<div id="page-container">
    <div id="content-wrap">
<div class="aboutHeader" id="firstHeader">
    <H1 class="headerTopLine">{{title}}</H1>
    <!-- <h1 class="headerBottomLine">{{title}}</h1> -->
    <h1>{{fullDate}}</h1>
    <H1 id="location">{{details.City}}, {{details.StateProvince}} {{countries[details.CountryId - 1]}}</H1>
    <div id="registration"></div>
</div>
<div class="content" id="mainContent">
    <div id="banner"></div>
<div id=description>
  
</div>


<div id="AdditionalResources"></div>

<div id="sponsors">

</div>
</div>
    </div>
<app-footer></app-footer>
</div>