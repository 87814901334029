import {
    trigger,
    transition,
    style,
    query,
    group,
    animateChild,
    animate,
    keyframes,
    state
  } from '@angular/animations';


// Basic

export const fader =
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter', [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          // transform: 'scale(0) translateY(90%)',
        }),
      ]),
      query(':leave', [
        animate('600ms ease', style({ 
          position: 'absolute',
        left: 0,
        width: '100%',
        opacity: 0,
        // transform: 'scale(.65)' 
      }
        )),
      ]),
      query(':enter', [
        animate('600ms ease', style({ opacity: 1,
          //  transform: 'scale(1) translateY(0)' 
          })),
      ])
    ]),
]);


export const faderDrop =
trigger('openClose', [
  // ...
  state('open', style({
    opacity: 0,
    transform: 'translateY(-10%)'
  
  })),
  state('closed', style({
    opacity: 1,
    transform: 'translateY(0)'
  })),
  transition('open => closed', [
    animate('1s')
  ]),
  transition('closed => open', [
    animate('0.5s')
  ]),
])


export const fadeElement =
trigger('fade', [
  transition('* <=> *', [
    query(':enter', [
      style({
        position: 'absolute',
        left: 0,
        width: '100%',
        opacity: 0,
        // transform: 'scale(0) translateY(90%)',
      }),
    ]),
    query(':leave', [
      animate('600ms ease', style({ 
        position: 'absolute',
      left: 0,
      width: '100%',
      opacity: 0,
      // transform: 'scale(.65)' 
    }
      )),
    ]),
    query(':enter', [
      animate('600ms ease', style({ opacity: 1,
        //  transform: 'scale(1) translateY(0)' 
        })),
    ])
  ]),
]);
    
  



// Positioned

export const slider =
  trigger('routeAnimations', [
    transition('* => 1', slideTo('left') ),
    transition('* => 6', slideTo('right') ),
    transition('6 => *', slideTo('left') ),
    transition('1 => *', slideTo('right') ),
    transition('two => three', slideTo('right') ),
    transition('three => two', slideTo('left') )
  ]);


export const transformer =
  trigger('routeAnimations', [
    transition('* => isLeft', translateTo({ x: -100, y: -100, rotate: -720 }) ),
    transition('* => isRight', translateTo({ x: 100, y: -100, rotate: 90 }) ),
    transition('isRight => *', translateTo({ x: -100, y: -100, rotate: 360 }) ),
    transition('isLeft => *', translateTo({ x: 100, y: -100, rotate: -360 }) )
]);




function slideTo(direction: any) {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'relative',
        top: 0,
        [direction]: 0,
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ position: 'absolute', [direction]: '-100%'})
    ]),
    group([
      query(':leave', [
        animate('600ms ease', style({ [direction]: '100%'}))
      ], optional),
      query(':enter', [
        animate('600ms ease', style({ [direction]: '0%'}))
      ])
    ]),
  ];
}


function translateTo({x = 100, y = 0, rotate = 0}) {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)`})
    ]),
    group([
      query(':leave', [
        animate('600ms ease-out', style({ transform: `translate(${x}%, ${y}%) rotate(${rotate}deg)`}))
      ], optional),
      query(':enter', [
        animate('600ms ease-out', style({ transform: `translate(0, 0) rotate(0)`}))
      ])
    ]),
  ];
}

