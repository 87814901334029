import { Component } from '@angular/core';
import{Router, NavigationEnd, RouterOutlet} from '@angular/router';


import {fader} from './route-animations'
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    fader,
  
 ]
})
export class AppComponent {
  title = 'APRA';

  isOpen = true;

  prepareRoute(outlet: RouterOutlet) {
    
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  constructor(public router: Router){   
    this.router.events.subscribe(event => {
       if(event instanceof NavigationEnd){
           gtag('config','UA-153778090-1', 
                 {
                   'page_path': event.urlAfterRedirects
                 }
                );
        }
     }
  )}
}
