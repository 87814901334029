import { Component, OnInit } from '@angular/core';
import { url } from 'inspector';
import {SessionDataService} from '../session-data.service'


@Component({
  selector: 'app-reman-connection',
  templateUrl: './reman-connection.component.html',
  styleUrls: ['./reman-connection.component.css']
})
export class RemanConnectionComponent implements OnInit {

  constructor(private data: SessionDataService) { }


// downloaFile(id){

//   document.getElementById("content").style.cursor = "wait";


//   this.data.downloadFile(id).subscribe((res :any) => {
   
//     const byteArray = new Uint8Array(atob(res.file).split('').map(char => char.charCodeAt(0)));


//      var newBlob = new Blob([byteArray], { type: "application/pdf" });
    
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
//     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//         window.navigator.msSaveOrOpenBlob(newBlob);
//         return;
//     }

    
//     document.getElementById("content").style.cursor = "auto";
//     const data = window.URL.createObjectURL(newBlob);
// window.open(data);
 
// });
// };
// folders = [];
// folderNames = [];
// files = [];
  ngOnInit(): void {
    this.data.uncheckAll();
//     this.data.getFolder(315635).subscribe(res => {

//       let folders: any = res;
      
//       folders.forEach(folder => {
// var months = ["January", "February", "March", "April", "May", "June",
//        "July", "August", "September", "October", "November", "December"];
//         this.data.getFolder(folder.FolderId).subscribe(res => {
//        let fileList: any = res;
      
// fileList = fileList.sort(function(a, b){
//  return months.indexOf(a.Name.slice(0, -9))
//       - months.indexOf(b.Name.slice(0, -9));
// });
//         this.folders.push({name: folder.Name, files: fileList});
//         this.folders.sort(function(a, b) {
//           return a.name.localeCompare(b.name);
//        });
//         });

//       });
     
     
//     })
  }

}
// "downloadFile(${file.FileId})