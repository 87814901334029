<div class="aboutHeader" id="firstHeader">
    <h1 class="headerBottomLine">HDRG</h1>
    </div>
    <div class="contentSection">
       
</div>

    <div class="contentSection">

      

        <div class="leftContent">
       <b>What is the HDRG?
    </b>
    <p>The Heavy Duty Remanufacturing Group was established in 2004 as an affinity group of APRA members who remanufacture a significant portion of their components for the heavy duty or commercial vehicle marketplace. Heavy duty is defined as class 4 through 8 trucks and all types of off-road vehicles. It should be noted that there are also many medium duty vehicles included in the commercial vehicle sector. HDRG is a market segment of the Automotive Parts Remanufacturers Association.
    </p>
    <p>HDRG is be devoted to representing the interests of the remanufacturers in the commercial vehicle market. There are different factors driving the commercial vehicle market then the light duty consumer market. The commercial markets view their vehicles as tools of their business and the purchasing characteristics are directed in that application.
    </p>
    <p>The remanufacturers need different tools and information to sell into this market. HDRG will foster the development of those tools and aid in forming the alliances necessary to market in that arena. Everything HDRG does is driven solely by the best interests of remanufacturers and the remanufacturing industry.
    </p>
    <b>Why was HDRG formed?</b>
    <p>The Automotive Parts Remanufacturers Association (APRA) was formed in 1941. From the beginning there have been a significant number of members who were remanufacturing/rebuilding heavy duty products. For over 30 years there has been a Heavy Duty Brake Division made up of companies who remanufacture air and vacuum brakes. There has also been a Heavy Duty Transmission, Driveline and Axle Division for over 25 years that has organized meetings and plant tours in this segment of the market. In addition to that, there have been many active members in the Electrical and Clutch heavy duty business that have served the association well since 1941.
    </p>
    <p>In 2004 we recognized that the heavy duty remanufacturers should be working to solve problems common to their marketplace and to network with each other to provide a unified voice to potential customers, the government, OE parts manufacturers, fleets, owner-operators, association and government agencies.
    </p>
        </div>
        <div class="photoParent">
        <img src="../../assets/images/Logos/2019_hdrg_logo_247w.png">
        <div class="hdroty" (click)="toggleoty()">
            <h2>Heavy Duty Remanufacturer of the Year 
            </h2>
            <img src="../../assets/images/howardGossage.png">
            <h3>Howard Gossage</h3>
            <h3>Heavy Quip dba Diesel Sales</h3>
            <span>Click for More</span>
           
        </div>

        
        </div>
        </div>
        <div *ngIf="showoty" (click)="toggleoty()">
        <div class="otyLetter" >
            <div class="otyContent">
            <h3>
                Heavy Duty Remanufacturer of the Year 
Howard Gossage and Heavy Quip dba Diesel Sales
            </h3><p>
	The Heavy Duty Reman Group (a Division of APRA), awarded Howard Gossage of Heavy Quip dba Diesel Sales of Chicago, IL with their Heavy Duty Remanufacturer of the Year Award on January 24, 2022 at the HDRG Summit held in Dallas, TX.
</p><p>
Howard Gossage and his team have evolved greatly since Gossage began his business in the industry almost 55 years ago. Today, Gossage is the founder and owner of Heavy Quip Inc. dba Diesel Sales. With a warehouse of 100,000 square feet, 100’s of engines in stock, 40,000 square feet of remanufacturing space, and a full line parts department - Gossage and his team handle it all.
</p><p>
Gossage began his business selling parts and engine cores. Gossage traveled all over the country buying and selling trailers full of cores for rebuilding to nearly all of the major remanufacturers. As time went on - Gossage looked to expand his business.
</p><p>
He began by buying completed product from many of the rebuilders he sold cores to… Leading him to become an authorized distributor or reseller of new replacement and remanufactured engines and parts for Deutz, Yanmar, Kubota, Isuzu, Perkins and John Deere.
</p><p>
Gossage rebranded his business in 2010. He acquired the expertise of engine remanufacturing expert, Randy Walker from Walker Equipment, beginning his venture as an engine remanufacturer by teaching all of the staff how to build an engine properly.   
</p><p>
At Diesel Sales, the remanufacturing technicians build to OEM specifications. They specialize in remanufactured Komatsu, Caterpillar and Cummins engines, mostly for off-road application. Aside from remanufacturing experts – Gossage employs an entire team. From sales people, freight handlers, accountants, marking specialists and website developers… The Diesel Sales does it all. In 2020, Gossage added a parts department to his team. The parts specialists supply hydraulic pumps, hydraulic cylinders, engine overhaul kits, and more.
</p><p>
Howard Gossage loves what he does and is eager to get to work every single day!
</p><p>
Please join me in congratulating Howard Gossage and Diesel Sales as the 2022 APRA/HDRG Heavy Duty Remanufacturer of the Year!
</p>
Regards,

Joe Kripli
APRA/HDRG President
</div>
        </div>
</div>
        <app-footer></app-footer>
