import { Component, OnInit } from '@angular/core';
import {SessionDataService} from "../session-data.service"

@Component({
  selector: 'app-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.css']
})
export class AdvertiseComponent implements OnInit {

  constructor(private data: SessionDataService) { }

  ngOnInit(): void {
    this.data.uncheckAll();
  }

}
