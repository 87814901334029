import { Component, OnInit } from '@angular/core';
import{SessionDataService} from '../session-data.service'


@Component({
  selector: 'app-core-finder',
  templateUrl: './core-finder.component.html',
  styleUrls: ['./core-finder.component.css']
})
export class CoreFinderComponent implements OnInit {

  constructor(private data: SessionDataService) { }

  ngOnInit(): void {
    this.data.uncheckAll();
  }

  submitRequest(f){
    if(f._directives[0].valid && f._directives[1].valid && f._directives[3].valid && f._directives[11].valid){
      let response= "Core Request: ";
      f._directives.forEach(element => {
        response = response + element.name + " " + element.viewModel + " "
      });
     this.data.postCoreRequest(response, "Core Request").subscribe((response: any)=> {
       if(response.Name== "Core Request"){
 document.getElementById("requestForm").innerHTML = `<h3>Core Finder Form</h3> <div>Thank you ${f._directives[0].viewModel}. Your core request is complete.</div>`
       }
     });
    
    }else{
      alert("Required field empty - Please complete all required fields")
    }
    
  }

}
