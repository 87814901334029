<img src="../../assets/images/70's/1970_Banner.jpg" class="banner" alt="">
<h1>1970's</h1>
<span>The 1970's were a time of networking and expansion. APRA membership bloomed to over 600 members, the association maintain seven technical clinics, and APRA's Annual Conventions welcomed over 1,200 attendees who came in from all over the world. </span>
<br><br>
<img src="../../assets/images/70's/1970_Convention_71-7.jpg" alt="">
<img src="../../assets/images/70's/1970_APRA_Convention_71-12.jpg" alt="">
<img src="../../assets/images/70's/APRA_Convention_70_Page_2.jpg" alt="">
<img src="../../assets/images/70's/1970_Convention_71-4.jpg" alt="">
<img src="../../assets/images/70's/APRA_Convention_71-5.jpg" alt="">
<img src="../../assets/images/70's/1970_APRA_Convention_71-13.jpg" alt="">
<img src="../../assets/images/70's/1970_APRA_Convention_70.jpg" alt="">
<br><br>
<span>APRA also celebrated it's 25th Convention in 1971. Click on the Show Guide cover below to flip through the Show Guide! 

</span>
<br><br>
<div class="subPics">
<img src="../../assets/images/70's/1971_ConventionBookCover.jpg" class="qtrPic" alt="">
<img src="../../assets/images/70's/APRA_Convention_70_Page_3.jpg" class='halfPic' alt="">
</div>
<img src="../../assets/images/70's/1970_APRA_Convention1.jpg" alt="">
<img src="../../assets/images/70's/APRA_Convention_71-9.jpg" alt="">
<img src="../../assets/images/70's/APRA_Convention_71-11.jpg" alt="">
