
<div class="archiveMenu" id="historicMenu">
    <a routerLink="HistoricHome" routerLinkActive="active"><div class="buttonDiv">Historic</div></a>
    <a routerLink="1940s" routerLinkActive="active"><div class="buttonDiv">1940s</div></a>
    <a routerLink="1950s" routerLinkActive="active"><div class="buttonDiv">1950s</div></a>
    <a routerLink="1960s" routerLinkActive="active"><div class="buttonDiv">1960s</div></a>
    <a routerLink="1970s" routerLinkActive="active"><div class="buttonDiv">1970s</div></a>
    <a routerLink="1980s" routerLinkActive="active"><div class="buttonDiv">1980s</div></a>

</div>
<div class="historicMenuButton">
<a (click)="TriggerHamburger()" class="icon" onclick="myFunction()">
  Archive Menu <i class="fa fa-bars"></i>
  </a>
  </div>
<!-- <img src="https://cdn.ymaws.com/apra.org/resource/resmgr/Images/APRA_75_Years_banner.jpg"> -->
<div class="router">
<router-outlet #outlet="outlet"></router-outlet>
</div>


<app-footer></app-footer>
