import { Component, OnInit } from '@angular/core';
import {SessionDataService} from '../session-data.service'
import {Router, NavigationEnd, RouterOutlet} from '@angular/router'

import {
  slider,
 
} from '../route-animations';

@Component({
  selector: 'app-historic',
  templateUrl: './historic.component.html',
  styleUrls: ['./historic.component.css'],
  animations: [slider]
})
export class HistoricComponent implements OnInit {

  constructor(private data: SessionDataService) { }


  ngOnInit(): void {
    this.data.uncheckAll();
  }
  TriggerHamburger() {
    var x = document.getElementById("historicMenu");
    if (x.style.display === "flex") {
      x.style.display = "none";
    } else {
      x.style.display = "flex";
    }
  }
}
