
<div class="aboutHeader" id="firstHeader">
<H1 class="headerTopLine">WHAT IS</H1>
<h1 class="headerBottomLine">APRA?</h1>
</div>
<div class="contentSection">
<h2>Supporting Remanufacturing since 1941</h2>

<p>
    At the Automotive Parts Remanufacturers Association, individuals make the difference. Success depends upon a strong member focus, quality service and continuous improvement by volunteers and staff members who show integrity, caring and initiative.  We work together to reach across job responsibilities, titles and organizational structure in order to share information and expertise. Teamwork brings out the best in APRA.
</p>

</div>

<div class="aboutHeader">
    <H1 class="headerTopLine">APRA’S</H1>
    <h1 class="headerBottomLine">MISSION</h1>
    </div>
    <div class="contentSection">
    <!-- <div class="mission"> -->
    <div class="missionMain">Our mission is to address the needs of the automotive and truck parts remanufacturing industry and to serve our members in four primary ways:</div>
    <div class="missionBlock">
    <div class="missionSub">
        <i class="fas fa-chalkboard"></i><br>
<br>

        By providing a wide range of quality products, services, workshops and educational information (including APRA’s REMAN Connection and E-Connection).</div>
    <div class="missionSub">
        <i class="fas fa-balance-scale"></i><br>
<br>

        Through legislative, legal and advocacy at various levels of government.</div>
    <div class="missionSub">
        <i class="fas fa-desktop"></i><br>
<br>

        By offering technical services, online catalogs, service bulletin access, a parts finder service and wiring diagrams.
    </div>
    <div class="missionSub"><i class="fas fa-handshake"></i><br>
        <br>
        As well as arranging many networking opportunities for members of the remanufacturing community.
    </div>
</div>
</div>
<div class="aboutHeader">
    <H1 class="headerTopLine">APRA’S</H1>
    <h1 class="headerBottomLine">STORY</h1>
</div>
<div id="story" class="contentSection">
    <p class="dropCap">
    The Automotive Parts Remanufacturers Association (APRA) was founded October 24, 1941 by R. A. Van Alen and Harry Lester in Los Angeles. At the first association convention in 1947 Harry Lester was still president, and attendance was roughly 50 people. Over the past 75 years membership has grown to about 1,000 member companies worldwide. Convention attendance exceeds 2,000 people with over 150 exhibit booths.
</p><p>
At the Automotive Parts Remanufacturers Association, individuals make the difference. Success depends upon a strong member focus, quality service and continuous improvement by volunteers and staff members who show integrity, caring and initiative.
</p><p>
APRA Globe	
Our business goes beyond simply meeting member needs. We work together to reach across job responsibilities, titles and organizational structure in order to share information and expertise. Teamwork brings out the best in APRA.
</p><p>
How we serve our members is crucial. Each of us enhances relationships with those we serve by being prompt, accurate, professional and by adding a personal touch. When problems arise, we turn them into opportunities to provide exceptional service.
</p><p>
We always look for ways to improve. We encourage innovative thinking to find new sources of income, to control costs, to enhance member service quality and to work more efficiently.
</p><p>
We value diversity among our volunteer members. They come from small, medium and large firms. They come from a variety of job backgrounds. They come from different product line perspectives and are either in the automotive or heavy duty business or both. They also come from different parts of the world. Some are rebuilders and some are suppliers, but they all work toward the success of our rebuilding industry.
</p><p>
As a result of this diversity, we encourage an environment where each individual can make a difference in the success of APRA. While the forces of change have never been stronger, our opportunities, have never been greater.
</p><p>
In 2021, APRA is celebrating 80 years of service to the remanufacturing industry!
</p>
</div>

<app-footer></app-footer>
