import { Component, OnInit } from '@angular/core';
import {SessionDataService} from '../session-data.service'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {



  constructor( private data: SessionDataService) { }

  animationTrigger:string;

  ngOnInit(): void {
   this.data.uncheckAll();
  }
  
}
