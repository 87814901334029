import { Component, OnInit } from '@angular/core';
import {SessionDataService} from '../session-data.service'

@Component({
  selector: 'app-hdrg',
  templateUrl: './hdrg.component.html',
  styleUrls: ['./hdrg.component.css']
})
export class HDRGComponent implements OnInit {

  constructor(private data: SessionDataService) { }

  showoty: boolean = false

  ngOnInit(): void {
    this.data.uncheckAll();
  }

  toggleoty(): void{
this.showoty = !this.showoty
  }

}
