import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import {SessionDataService} from '../session-data.service'

@Component({
  selector: 'app-e-connection',
  templateUrl: './e-connection.component.html',
  styleUrls: ['./e-connection.component.css']
})
export class EConnectionComponent implements OnInit {

  constructor(private data: SessionDataService) { }
  eConnections: any = [];
eMail: any;

  ngOnInit(): void {
    this.data.uncheckAll();
    // this.data.geteConnection().subscribe((response: any)=>{
     
    //   response.forEach(element => {
    //     let month = element.Date.slice(5,6)
    //     let months= ["January","February","March","April","May","June","July",
    //     "August","September","October","November","December"]
    //     this.eConnections.push({id: element.CommunicationId, date: months[element.Date.slice(5, 7)-1]+' '+element.Date.slice(8, 10)+', '+element.Date.slice(0, 4)})
    //   });
    // })
    
  }
  submitRequest(f){
    if(f._directives[0].valid && f._directives[1].valid && f._directives[2].valid){
      let response= "Core Request: ";
      f._directives.forEach(element => {
        response = response + element.name + " " + element.viewModel + " "
      });
     this.data.postCoreRequest(response, "eConnection Signup").subscribe((response: any)=> {
       if(response.Name== "eConnection Signup"){
 document.getElementById("requestForm").innerHTML = `<h3>EConnection Subscription Form</h3> <div>Thank you ${f._directives[0].viewModel}. You are subscribed.</div>`
       }
     });
    
    }else{
      alert("Required field empty - Please complete all required fields")
    }
    
  }


  getEmail(i){
this.data.geteMail(i).subscribe((response: any)=>{
  console.log(response)
});
  }

}
