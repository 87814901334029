import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a1970s',
  templateUrl: './a1970s.component.html',
  styleUrls: ['./a1970s.component.css']
})
export class A1970sComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
