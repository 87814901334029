import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a1980s',
  templateUrl: './a1980s.component.html',
  styleUrls: ['./a1980s.component.css']
})
export class A1980sComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
