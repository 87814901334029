<div class="aboutHeader" id="firstHeader">
    <H1 class="headerTopLine">APRA</H1>
    <h1 class="headerBottomLine">TECH</h1>
    </div>
<!-- 
    <a href="http://www.apratech.org/welcome/welcome2.php"> MEMBERS - CLICK HERE TO ACCESS APRA-Tech</a> -->

    <div class="contentSection">
        <div class="buttonContainer">
        <a href="http://www.apratech.org/sso.php" class="accessButton"> MEMBERS - CLICK HERE TO ACCESS APRA-Tech</a>
        </div>

        <h2>APRA-Tech, an entirely new section of the APRA website, is devoted exclusively to technical resources. 
        </h2>
        <p>APRA-Tech provides APRA Members with a wealth of diverse information, including seminars, videos, articles from Mohammad Samii and Delco-Remy, plus a easy and effective way to navigate all the resources by using the search bar too. The APRA-Tech Website is made up of seven different content areas:</p>
<div>
    <span class="subheader">Auto Electric corner</span>
    <br>
    <span>Library of Mohammad Samii articles about his auto electric shop,

        offering tips to save time and money. Also searchable by vehicle,
        
        year, topic, OE number, reference number or column date</span>
</div>
<div>
    <span class="subheader">Delco-Remy Out-of-print Information</span>
    <br>
<span>Archive of articles no longer available from the OEM by Delco-Remy from

    1920's-1990's. </span>
</div>
<div>
    <span class="subheader" >Guides/Manuals</span>
    <br>
<span>Documents, Cross-references, service guides, OEM training manuals and Delco-Remy Training Chart Manuals
    </span>
</div>
<div>
    <span class="subheader">Seminars</span>
    <br>
<span>Easy-to-view web based presentations on diverse subjects (turbo remanufacturing, rack and pinion remanufacturing, heavy duty cleaning to name just a few) </span>
</div>
<div>
    <span class="subheader">Videos</span>
    <br>
<span>Technical videos produced in high definition by APRA-Tech </span>
</div>
<div>
    <span class="subheader">Search</span>
    <br>
<span>Google-like search style helps members find anything on the site
</span>
</div>
<!-- <div>
    <span>Forums</span>
<span>Coming soon! When completed, the forums will be a place for an online

    community of remanufacturers to ask questions, share answers and
    
    communicate with each other.</span>
</div> -->

    </div>

    <app-footer></app-footer>
