<div class="aboutHeader" id="firstHeader">
    <H1 class="headerTopLine">APRA</H1>
    <h1 class="headerBottomLine">CORE_FINDER</h1>
    </div>
    <div class="contentSection">
        <div class="leftContent">
            <img src="../../assets/images/Logos/apra_core_finder.png">
        <h2>Leave it to us to get you those hard to find cores!</h2>
        
       
            APRA members now have a solution to find difficult cores: APRA Core Finder: A member only service quickly and easily connecting members to over 60 leading core suppliers.

<br>
<br>
            Every day, APRA members need cores that they simply cannot locate efficiently. APRA has built APRA Core Finder to solve this problem.  
       <br>
        <br>
            <b>Here’s how it works:</b>
            <ul>
                <li>Members provide APRA with detailed information about the core/cores they need.</li>
                <li>APRA quickly distributes member requests to participating APRA Core Supplier members in good standing. 
                </li>
                <li>APRA Core Suppliers review the requests and may directly contact you if they have a solution.
                </li>
            </ul>
            <br>
            <b>APRA Core Finder is FAST & EASY
            </b>
            <br>
            <br>
           <b>Remanufacturers:</b> Send an email to cores@apra.org or  fill out the short form on this page. APRA will handle the rest.
           <br>
           <br>
        
          <b>Core Suppliers:</b> Simply provide the following information to cores@apra.org 
           The point of contact (name and email address) at your company to receive APRA Core Finder requests.

           A copy of your logo for APRA to promote your participation. 

        </div>
        <div class="photoParent">
        <img src="../../assets/images/Logos/apra_core_finder.png">
        <div class="coreForm" id="requestForm">
                <h3>Core Finder Form</h3>
        <form #userForm="ngForm" (ngSubmit)='submitRequest(userForm)'>

            <div class="formGroup">
                <label>First Name*</label>
                <input type="text" required ngModel  #FirstName="ngModel" name="FirstName">
                <div class="required" *ngIf="FirstName.touched && !FirstName.valid">First Name is required</div>
            </div>
            <div class="formGroup">
                <label>Last Name*</label>
                <input type="text" required ngModel  #LastName="ngModel" name="Last Name">
                <div class="required" *ngIf="LastName.touched && !LastName.valid">Last Name is required</div>
            </div>
            <div class="formGroup">
                <label>Organization</label>
                <input type="text" ngModel name="Organization">
            </div>
            <div class="formGroup">
                <label>Email Address*</label>
                <input type="email" required ngModel email #Email="ngModel" name="Email">
                <div class="required" *ngIf="Email.touched && !Email.valid">Valid Email Address is required</div>
            </div>
            <div class="formGroup">
                <label>Phone</label>
                <input type="text" ngModel name="Phone">
            </div>
            <div class="formGroup">
                <label>Address Line 1</label>
                <input type="text" ngModel name="Address Line 1">
            </div>
            <div class="formGroup">
                <label>Address Line 2</label>
                <input type="text" ngModel name="Address Line 2">
            </div>
            <div class="formGroup">
                <label>City</label>
                <input type="text" ngModel name="City">
            </div>
            <div class="formGroup">
                <label>State</label>
                <input type="text" ngModel name="State">
            </div>
            <div class="formGroup">
                <label>Zip/Postal Code</label>
                <input type="text" ngModel name="Zip/Postal Code">
            </div>
            <div class="formGroup">
                <label>Country</label>
                <input type="text" ngModel name="Country">
            </div>
            <div class="formGroup">
                <label>Core Request*</label>
                <input type="text" required ngModel  #core="ngModel" name="Core Request">
                <div class="required" *ngIf="core.touched && !core.valid">Core Request is required</div>
            </div>
            
            <button>Submit</button>
        </form>
        </div>
        
        </div>
        </div>
        

        <app-footer></app-footer>
