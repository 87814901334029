import { Component, OnInit,  ViewChild, ElementRef, Inject  } from '@angular/core';
import {SessionDataService} from '../session-data.service'
import { Router } from "@angular/router";


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']


})
export class MenuComponent implements OnInit {
events : any;
  constructor(private data: SessionDataService, private router: Router) { 

  }
  
  ngOnInit(): void {

this.data.getWebsiteEvents().subscribe((res: [])=>{
  this.events = res
})
 
  }

  eventRouter( eventName, eventId ){
this.router.navigate(["event"], {
  queryParams:{
    keyword: eventName,
    ID: eventId
  }
})
  }

}