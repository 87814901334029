
<img src="../../assets/images/APRA 80th Anniversary.jpg">
<br>
<div class="content">
   <div class="timeline">
      <div class="timelineItem">
         <div></div>
         <h3>1941</h3>
         <span>Foundation of APRA
            California</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>1947</h3>
         <span>1st National Convention of APRA
            in Chicago</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>1949</h3>
         <span>APRA has 150 Members
</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>1960</h3>
         <span>1st APRA video explaining remanufacturing and benefits</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>1970</h3>
         <span>Annual Convention reaches 1200 attendance

         </span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>1975</h3>
         <span>APRA has 600 members

         </span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>1989
         </h3>
         <span>Foundation of APRA European Division

         </span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>1990
         </h3>
         <span>1st APRA European Symposium in Frankfurt, Germany</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>1991</h3>
         <span>50th birthday of APRA

         </span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>2001</h3>
         <span>1st ReMaTec exhibition in Amsterdam

         </span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>2011
         </h3>
         <span>Foundation of APRA Asia Pacific Division

         </span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>2015
         </h3>
         <span>G7 summit: Remanufacturing is part of resource efficiency; BIG R Show, 1350 attendance from 55 countries</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>2016</h3>
         <span>75th birthday of APRA; APRA has 1000 members</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>2017</h3>
         <span>ReMaTec exhibition reaches 3600 attendance</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>2018</h3>
         <span>Foundation of APRA European legal entity</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>2019</h3>
         <span>30th birthday of APRA Europe</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>2021</h3>
         <span>APRA Europe merged with FIRM</span>
      </div>
      <div class="timelineItem">
         <div></div>
         <h3>2021</h3>
         <span>80th birthday of APRA</span>
      </div>
   </div>
   <div>
<div class="dropCap"><p>In 1941, due to the adverse decision of the Circuit Court of Appeals in an excise tax assessment covering rewound armatures, a group of rebuilders from Los Angeles subscribed to a fund to present a hearing before the Senate Finance Committee in an effort to clarify the Excise Tax Regulation, as it applied to rebuilt units.
</p>
    <hr>
    
    <!-- <img src="https://apra.org/resource/resmgr/images/paragraph_div.png" alt="divider" class="divider"> -->
   <p> In October, 1941, the Automotive Parts Rebuilders Association was duly incorporated in California, as a non-profit organization composed of rebuilders and suppliers who could cooperate in the best interest of automotive parts rebuilders.
</p>

   </div>


<p>The Automotive Parts Remanufacturers Association began as a small group of rebuilders in California, and it did not take long for APRA to become active for its members. On April 2, 1942, three representatives from APRA went to testify before Congress concerning the tax on rebuilt auto parts: Meredith M. Daubin, Albert D. Goldman, and A. S. Holzwasser. The three men encouraged the House of Representatives to clarify the difference between manufacturing and re-manufacturing. They argued that the excise tax should be levied on only newly manufactured auto parts and should not be applied to remanufactured parts.</p>
<p>During World War II, there was a tremendous need to reuse automotive and truck parts. Natural resources were scarce, since they were needed to support the war effort to build planes, ships, tanks, etc. APRA was able to promote the benefits of remanufacturing while also directing its attention to various other matters such as work with the Office of Price Administration, War Production Board, and other government agencies.</p>
<p>After the war, in December of 1947, APRA held its first real National Convention in Chicago of over 100 in attendance. At that time, there were around 150 companies who were members of APRA. As more and more rebuilders throughout the country began to realize the need for an association to promote their industry and represent their interests at the national level, the membership of the association increased.

</p>
<p>75 years later, attendance at APRA's 2015 Big R/ReMaTecUSA Show topped over 1,350 attendees from over 55 countries, and APRA Membership has grown to over 1,000 members. Expanding to meet the needs of the growing remanufacturing industry, APRA not only has headquarters outside Washington D.C., but also maintains an office in Europe and in Asia in order to represent the industry on a global level. APRA is not just attuned to the needs of its members, but to the undercurrents of the global market.

</p>
<p>As the industry has evolved over the decades, APRA is proud to have served its members for 75 years. APRA is the "Voice of Remanufacturing" and is getting stronger all the time!

</p>
</div>
</div>