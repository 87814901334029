import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { gsap } from 'gsap';
import { HttpClientModule  } from '@angular/common/http'
import { SessionDataService } from '../app/session-data.service'
import {FormsModule} from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'


import { RouterModule, Routes, ParamMap, NavigationEnd } from '@angular/router';

import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { BoardOfDirectorsComponent } from './board-of-directors/board-of-directors.component';
import { HistoricComponent } from './historic/historic.component';
import { MembershipComponent } from './membership/membership.component';
import { from } from 'rxjs';
import { FooterComponent } from './footer/footer.component';
import { HistoricHomeComponent } from './historic-home/historic-home.component';
import { A1940Component } from './a1940/a1940.component';
import { A1950sComponent } from './a1950s/a1950s.component';
import { A1960sComponent } from './a1960s/a1960s.component';
import { A1970sComponent } from './a1970s/a1970s.component';
import { A1980sComponent } from './a1980s/a1980s.component';
import { EventComponent } from './event/event.component';
import { RemanConnectionComponent } from './reman-connection/reman-connection.component';
import { AdvertiseComponent } from './advertise/advertise.component';
import { APRATechComponent } from './apratech/apratech.component';
import { CoreFinderComponent } from './core-finder/core-finder.component';
import { HDRGComponent } from './hdrg/hdrg.component';
import { EConnectionComponent } from './e-connection/e-connection.component';
import { GrowthZoneTemplateComponent } from './growth-zone-template/growth-zone-template.component';
import { PressComponent } from './press/press.component';
import { ECon1Component } from './e-con1/e-con1.component';
import { ECon2Component } from './e-con2/e-con2.component';
import { ECon3Component } from './e-con3/e-con3.component';
import { ECon4Component } from './e-con4/e-con4.component';
import { ECon5Component } from './e-con5/e-con5.component';
import { ECon6Component } from './e-con6/e-con6.component';


const appRoutes : Routes = [
  { path: 'GrowthZone_Template', component: GrowthZoneTemplateComponent },
  { path: 'Home', component: HomeComponent, data: { animation: 'home' }   },
  { path: 'About', component: AboutComponent, data: { animation: 'about' }  },
  { path: 'Board_of_Directors', component: BoardOfDirectorsComponent, data: { animation: 'board' } },
  { path: 'Historic', component: HistoricComponent, data: { animation: 'historic' },  children: [ 
    { path: "1940s", component: A1940Component, data: { animation: '2' } },
    { path: "1950s", component: A1950sComponent, data: { animation: '3' } },
    { path: "1960s", component: A1960sComponent, data: { animation: '4' } },
    { path: "1970s", component: A1970sComponent, data: { animation: '5' } },
    { path: "1980s", component: A1980sComponent, data: { animation: '6' } },
    { path: 'HistoricHome', component: HistoricHomeComponent, data: { animation: '1' } }
  ] },
  {path: "eConnection_Archive", component: EConnectionComponent, data: { animation: 'econnection' }, children: [
    { path: "eCon1", component: ECon1Component, data: { animation: 'e' } },
  { path: "eCon2", component: ECon2Component, data: { animation: 'e' } },
  { path: "eCon3", component: ECon3Component, data: { animation: 'e' } },
  { path: "eCon4", component: ECon4Component, data: { animation: 'e' } },
  { path: "eCon5", component: ECon5Component, data: { animation: 'e' } },
  { path: "eCon6", component: ECon6Component, data: { animation: 'e' } }
 ]},
  {path: "press_Release", component: PressComponent, data: { animation: 'press' } },

  { path: 'Membership', component: MembershipComponent, data: { animation: 'member' }  },
  { path: '',redirectTo: 'Home', pathMatch: 'full'},
  {path: 'event', component: EventComponent, data: { animation: 'event' } },
  {path: 'Reman_Connection', component: RemanConnectionComponent, data: { animation: 'reman' } },
  {path: 'Advertising', component: AdvertiseComponent, data: { animation: 'ad' } },
  {path: 'APRATech', component: APRATechComponent, data: { animation: 'tech' } },
  {path: "Core_Finder", component: CoreFinderComponent, data: { animation: 'core' } },
  {path: "HDRG", component: HDRGComponent, data: { animation: 'hdrg' } }
]

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    AboutComponent,
    BoardOfDirectorsComponent,
    HistoricComponent,
    MembershipComponent,
    FooterComponent,
    HistoricHomeComponent,
    A1940Component,
    A1950sComponent,
    A1960sComponent,
    A1970sComponent,
    A1980sComponent,
    EventComponent,
    RemanConnectionComponent,
    AdvertiseComponent,
    APRATechComponent,
    CoreFinderComponent,
    HDRGComponent,
    EConnectionComponent,
    GrowthZoneTemplateComponent,
    PressComponent,
    ECon1Component,
    ECon2Component,
    ECon3Component,
    ECon4Component,
    ECon5Component,
    ECon6Component,
 
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes, { useHash: true}),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  providers: [SessionDataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
