import { Component, OnInit } from '@angular/core';
import { SessionDataService } from '../session-data.service';


@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {

  constructor(private data: SessionDataService) { }

  ngOnInit(): void {
    this.data.uncheckAll();
  }

  savings: any[]= [
    {type: 'Business Texting', message: 'SAVE UP TO 25% OFF', logos: ['Moblico.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=2765']},
    {type: 'Credit Card Processing', message: 'SAVE ON PROCESSING COSTS', logos: ['aura.png'], links: ['https://go.risewithaurora.com/partner-apra']},
    {type: 'Email Marketing', message: 'UP TO 25% OFF', logos: ['constantContact.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=651']},
    {type: 'Fuel', message: 'SAVE UP TO 20¢ PER GALLON', logos: ['conoco.png', 'exxonMobile.png', 'shell.png', 'stripes.png', 'sunoco.png', 'wex.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=1385', 'https://apra.savings4members.com/programs/views/green/external.html?id=840', 'https://apra.savings4members.com/programs/views/green/external.html?id=2623', 'https://apra.savings4members.com/programs/views/green/external.html?id=1948', 'https://apra.savings4members.com/programs/views/green/external.html?id=1788', 'https://apra.savings4members.com/programs/views/green/external.html?id=2096']},
    {type: 'Office Supplies', message: 'SAVE UP TO 65% OFF', logos: ['officeDepot.png', 'staples.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=1976', 'https://apra.savings4members.com/programs/views/green/external.html?id=3']},
    {type: 'Payroll', message: 'SAVE UP TO 40% ON FEES', logos: ['ADP.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=7']},
    {type: 'Propane', message: 'SAVE PER CYLINDER', logos: ['ferrellgas.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=1721']},
    {type: 'Rental Vehicles', message: 'SAVE UP TO 15% OFF', logos: ['penske.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=2177']},
    {type: 'Shipping', message: 'SAVE UP TO 75% OFF', logos: ['lamprey.png', 'ups.png', 'YRCFreight.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=592', 'https://apra.savings4members.com/programs/views/green/external.html?id=2001', 'https://apra.savings4members.com/programs/views/green/external.html?id=12']},
    {type: 'Supplies', message: 'GET EXCLUSIVE DISCOUNTS', logos: ['ace.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=2257']},
    {type: 'Uniforms', message: 'SAVE 20-40% OFF', logos: ['unifirst.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=652']},
    {type: 'Waste & Recycling', message: 'SAVE 20-40% OFF', logos: ['wasteFocus.png'], links: ['https://apra.savings4members.com/programs/views/green/external.html?id=1646']}
    
  ];



}
