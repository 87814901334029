import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-growth-zone-template',
  templateUrl: './growth-zone-template.component.html',
  styleUrls: ['./growth-zone-template.component.css']
})
export class GrowthZoneTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
