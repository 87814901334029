<div id="greenBackground" #greenBackground></div>
<div id="whiteBackground" #whiteBackground></div>

<div id="mainHomeBlock" #mainHomeBlock>
    <p>Explore our great membership benefits today!</p>
    <a routerLink="/Membership" routerLinkActive="active" id="mainButton">Discover</a>
    </div>

  


<img src="../../assets/images/Original APRA Color Logo.svg" alt="" id="mainLogo">
<!-- <a href="https://www.oecgroup.com/"><img src="../../assets/images/banners/auto parts banner ad final.jpg" alt="" id="banner"></a> -->


<div id="subHomeBlock" class="firstblock">
    <a href={{this.adUrl}}><img src={{this.adImage}} alt="" id="banner"></a>
</div>


<!-- <a href={{this.adUrl}}><img src={{this.adImage}} alt="" id="banner"></a> -->





<div id="subHomeBlock">
<!-- <a href=""><div class="subButton" id="eventButton"><span>APRA Europe</span></div></a> -->
<a href="https://automotivepartsremanufacturersassociations.growthzoneapp.com/MIC/8993204/8130419/#/InfoHub"><div class="subButton" id="coreButton"><span>Member Hub</span></div></a>
<a routerLink="/Advertising" routerLinkActive="active"><div class="subButton" id="adButton"><span>Advertisements</span></div></a>
</div>

<div id="homeCalendar">
    <h1>Calendar
    </h1>
    <div class="calandarEntries">

        <div *ngFor="let event of calendar" class="calendarEntry">
            

                <div class="date">
                    <span class="day">
                        {{event.StartDate}}
    
                    </span>
                    <span class="month">{{Months[event.StartDateLocal]}}</span>
                </div>
                <div class="date">
                   <span>{{event.ParentListId}}</span>
                </div>
                <div class="date">
                    <span class="day">
                        {{event.EndDate}}
    
                    </span>
                    <span class="month">{{Months[event.EndDateLocal]}}</span>
                </div>
                <div class="event">
                    <span class="eventTitle">{{event.Name}}</span>
                    <br>
                    {{event.Description}}</div>
                    </div>

</div>
    
</div>




<div id="hello" #hello>Hello world</div>

<app-footer></app-footer>
