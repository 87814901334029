<img src="../../assets/images/1960_Banner.jpg" class="banner">
<h1>1960's</h1>
<span>As APRA celebrates 25 years of service to the industry, it continues to grow into publications, clinics and networking events. </span><br><br>
<img src="../../assets/images/History_APRA.jpg" alt="">
<br>
<br>
<span>Donald W. Westling wrote editorials for the APRA Newspaper at the time, providing an excellent snapshot of the industry during the 1960's. Click here to view more editorials from the president, and other APRA publications from that decade.</span>
<br>
<br>
<div class="newsStrips">
<img src="../../assets/images/President_4.jpg" alt="" class="strip">
<img src="../../assets/images/President_5.jpg" alt="" class="strip">
<img src="../../assets/images/President_6.jpg" alt="" class="strip">
</div>
<br>
<br>
<span>APRA continued to offer various clinics through the country, which grew in popularity and in content. Here are some snapshots of those events! 

</span>
<br>
<br>
<img src="../../assets/images/1960-3.jpg" alt="">
<span class="caption">May 4, 1964- APRA Members peruse Chevrolet Display at the Transmission Institute Clinic in Detroit, Michigan. 

</span>
<br>
<br>

<img src="../../assets/images/1960-1.jpg" alt="">
<img src="../../assets/images/Meeting.jpg" alt="">
<span class="caption">Members of the APRA Institute of Armature and Generator Rebuilders attending the Technical Clinic held jUNE 23-25, 1960 AT Winnipeg, Canada. The entire group spent a full half day in the rebuilding plants of the Townsend Auto Electric Co. and the Wilson Auto Electric Co. "The Canadians certainly surprised the group with the perfectionist ideas and modern facilities for re-building, and the Clinic as a whole was an unqualified success according to those who went." </span>
<br>
<br>
    <img src="../../assets/images/1960.jpg">
    <img src="../../assets/images/APRANS.jpg">
    <br>
<br>
    <span>APRA even put together its own video explaining rebuilding and how it could be profitable for anyone.Click <a href="https://youtu.be/5oQ7z2Ue5bQ">here</a> to view that video! 
    </span>
    <img src="../../assets/images/apra_video.jpg" alt="">