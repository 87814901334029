import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a1950s',
  templateUrl: './a1950s.component.html',
  styleUrls: ['./a1950s.component.css']
})
export class A1950sComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
