<img src="../../assets/images/50's/APRA_1950_Banner.jpg" alt="" class="banner">
<h1>1950's</h1>
<span>APRA was well established and booming by the time the 1950's came about. APRA began to launch a series of clinics ranging from armature. The first electrical clinic was held at Lock Haven, Pennsylvania June 18-20, 1959 (pictured above).
</span>
<br>
<br>
<img src="../../assets/images/50's/SecondTech_Clinic.jpg" alt="">
<span>The second technical clinic was sponsored by Stanley Peterson and John Goetzfried, Armature Re-Winding Company (Minneapolis, Minnesota) on June 26-28, 1959. Upper left inset, H. B. Hastings, The Hastings Company, Winston-Salem, N.Ca, President of the Automotive Parts Rebuild­ers Association, also seated at the head table. Standing, left to right, Stanley Peterson, Chair­man of the clinic, and Nathan M. Roberts, Ex­ecutive Director, Automotive Parts Rebuilders Association, Chicago. Inset extreme right, W. J. Skamarak, General Armature & Mfg. Co., Lock Haven, Pa., Chairman of the Board of Governors of the Armature and Generator Institute, also seat­ed at the head table.
</span>
<br>
<br>
<img src="../../assets/images/50's/APRA_1950_Page_01.jpg" alt="">
<span>Board of Directors Meeting, Conrad Hilton Hotel Chicago January 31-February 1, 1958. Seated, from left to right, Charles R. Hicks (Kokomo, Indiana) Treasurer; Nicholas Cardone, (Philadelphia, Pennsylvania); Kenneth Palmer (Milwaukee); Wm. Adams, (Toronto); Irving Chafetz (Memphsis); 3rd Vice President J.B. Boulton, Jr. (Oklahoma City), Secretary Stanely Peterson (Minneapolis), 1st VP H.B. Hastings (King, North Carolina), President Wm. G. Weldon (Chicago, Illinois), R.S. Bishop (Fort Worth), A.S. Holswasser (Boston), K.E. Goss (Denver); 2nd Vice President R.R. Neil, Jr. (Nashville), Norman A. White (Minneapolis); Ralph W. Ball (Detroit); Evan O. Thomas Jr. (Pasadena, California); Chairman Walter Buben (Chicago), Executive Secretary Nathan M. Roberts (Chicago).   
</span>
<br>
<br>
<br>
<span>APRA also hosted the annual convention held in Chicago each fall, where awards were given out to APRA Members, the establishment of the Ladies Luncheon and Miss APRA, and networking and sessions were held for the benefit of the association numbers. </span>
<br>
<br>

<img src="../../assets/images/50's/1951_Convention.jpg" alt="">
<span>APRA's Convention at the Conrad Hilton Hotel- November 10, 1951. </span>
<br>
<br>
<img src="../../assets/images/50's/1957_Convention.jpg" alt="">
<span>APRA'S Convention Meeting at the Congress Hotel, September 12th, 1957. </span>
<br>
<br>
<img src="../../assets/images/50's/Ladies_Luncheon-1.jpg" alt="">
<span>APRA's Ladies Luncheon began in the 1950's. The ladies would gather together at the annual convention while the men were at the Trade Show and sessions.</span>
<br>
<br>
<img src="../../assets/images/50's/APRA_1950_Page_04.jpg" alt="">
<span>One of the awards given out at the APRA Convention was the Advertising Contest Award: Pictured here is Joe Dinwiddle, Champion Parts Rebuilders, Inc. Chairman of the APRA advertising committee and APRA President Bill Weldon of Van Bergen & Greener, Inc. Chicago. Weldon's firm won 1st place for companies with less than 25 employees. </span>
<br>
<br>
<img src="../../assets/images/50's/APRA_1950_Page_03.jpg" alt="">