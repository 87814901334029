<div class="aboutHeader" id="firstHeader">
    <H1 class="headerTopLine">ADVERTISE</H1>
    <h1 class="headerBottomLine">WITH_APRA</h1>
    </div>
    <div class="contentSection">
        <div class="leftContent">
        <h2>Spotlight your brand for the industry</h2>
        
        <p>
Experience the benefits of highlighting your brand in remanufacturing.  Take adavantage of reaching a highly targeted audience through both print and digital media.  
        </p>
        <p>See our media kit <a href="../../assets/images/2023 APRA Media Kit.pdf" download="APRA_MediaKit_2023">here</a> for available options.
            <br>
            <br>
            Questions? Contact <a href="mailto:kripli@apra.org">kripli@apra.org</a>
        </p>
        </div>
        <div class="photoParent">
            <a href="../../assets/images/2023 APRA Media Kit.pdf" download="APRA_MediaKit_2023"><img src="../../assets/images/media_kit.jpg">
                </a>
        </div>
        </div>
        <app-footer></app-footer>
