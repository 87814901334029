import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import {SessionDataService} from "../session-data.service"

@Component({
  selector: 'app-apratech',
  templateUrl: './apratech.component.html',
  styleUrls: ['./apratech.component.css']
})
export class APRATechComponent implements OnInit {

  constructor(private data: SessionDataService) { }

  ngOnInit(): void {
    this.data.uncheckAll();
  }

}
