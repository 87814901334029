<div class="header"><span class="headSlim">BOARD OF </span><span class="headBold">DIRECTORS</span></div>

<div class="boardGrid">






<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/RyaWilson.jpg" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">Ryan </span><span class="lastName">Wilson</span>   </div>
    <span>Chairperson</span>
    <span>BBB Industries,  LLC</span>
    <a href="mailto:rwilson@bbbind.com"><i class="far fa-envelope"></i></a>
    </div>
</div>

<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/Gil Portrait.jpg" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">Gilbert  </span><span class="lastName">Ramirez </span>   </div>
    <span>Vice Chairperson</span>
    <span>DENSO Products and Services Americas, Inc.</span>
    <a href="mailto:rhaas@advancedstarter.com"><i class="far fa-envelope"></i></a>
    </div>
</div>


<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/Jack_Daul.jpeg" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">Jack </span><span class="lastName">Daul</span>   </div>
        <span>Treasurer    </span>
    <span>Allen Woods & Associates    </span>
    <a href="mailto:jack@allenwoods.com"><i class="far fa-envelope"></i></a>
    </div>
</div>

<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/Emily Knopf.jpeg" alt="">
   <div class="MemberText">
    <div class="memberName">Emily Knopf</div>
    <span>Secretary</span>
    <span>Buffalo Core Supply</span>
    </div>
</div>

<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/Joe_Kripli_b.png" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">Joe </span><span class="lastName">Kripli</span>   </div>
    <span>President and
        Chairman of Past Chairman's Committee </span>
    <span>D & W Diesel    </span>
    <a href="mailto:kripli@apra.org"><i class="far fa-envelope"></i></a>
    </div>
</div>

<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/RichHaas.jpg" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">Rich </span><span class="lastName">Haas</span>   </div>
    <span>Immediate Past Chairperson</span>
    <span>Heavy Duty HQ</span>
    <a href="mailto:rhaas@advancedstarter.com"><i class="far fa-envelope"></i></a>
    </div>
</div>

<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/Greg_Arsenault.jpg" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">Greg </span><span class="lastName">Arsenault</span>   </div>
    
    <span>Past Chairperson</span>
    <span>Titan Power Products, LLC

    </span>
    <a href="mailto:gregarsenault1955@gmail.com"><i class="far fa-envelope"></i></a>
    </div>
</div>

<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/dean_conner.png" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">Dean </span><span class="lastName">Conner</span></div>
    <span>Past Chairperson and Electrical Division Representative</span>
    <span>Conner's Repair Service, LLC    </span>
    <a href="mailto:delcodean@gmail.com"><i class="far fa-envelope"></i></a>
    </div>
</div>
<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/Tom_Jackson.jpg" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">TOM </span><span class="lastName">JACKSON</span>   </div>
    <span>
         Core Supplier Representative</span>
    <span> Rebuilders Automotive Supply Company

    </span>
    <a href="mailto:tjackson@coresupply.com"><i class="far fa-envelope"></i></a>
    </div>
</div>
<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/Joseph_Chow_b.png" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">JOSEPH </span><span class="lastName">CHOW</span>   </div>
    <span>APRA Asia-Pacific Chairman    </span>
    <span>Lanceed</span>
    <a href="mailto:qiaous@gmail.com"><i class="far fa-envelope"></i></a>
    </div>
</div>
<div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/daniel_koehler_b.png" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">DR.-ING. DANIEL C. F.  </span><span class="lastName">KÖHLER</span>   </div>
    <span>APRA-Europe
        Chairman</span>
    <span>BPW Bergische Achsen KG    </span>
    <a href="mailto:koehlerd@bpw.de"><i class="far fa-envelope"></i></a>
    </div>
</div>
<!-- <div class="member">
    <img class="headShot" src="../../assets/images/boardOfDirectors/Tom_Dunn_b.png" alt="">
   <div class="MemberText">
    <div class="memberName"><span class="firstName">TOM </span><span class="lastName">DUNN</span>   </div>
    <span>Past Chairman (2018-2019)</span>
    <span>Crumbliss Automotive Test Equipment
        Vensel Enterprises</span>
    <a href="mailto:tom@aapinc.com"><i class="far fa-envelope"></i></a>
    </div>
</div> -->

</div>

<app-footer></app-footer>
