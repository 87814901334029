<div id="page-container">
  <div id="content-wrap">
    <img
      src="../../assets/images/Logos/apra_econnection_logo.png"
      class="logo"
    />
    <div class="content">
      <div class="archive">
        <h1>Recent E-Connections</h1>
        <div class="mailList">
          <!-- <div *ngFor="let item of eConnections; let i = index" class="mailItem">
            <span (click)="getEmail(item.id)">{{item.date}}</span>
        </div> -->

        <a routerLink="eCon1" routerLinkActive="active" class="mailItem">
            <span>June 2, 2023</span>
          </a>

          <a routerLink="eCon2" routerLinkActive="active" class="mailItem">
            <span>June 1, 2023</span>
          </a>

          <a routerLink="eCon3" routerLinkActive="active" class="mailItem">
            <span>May 9, 2023</span>
          </a>

          <a routerLink="eCon4" routerLinkActive="active" class="mailItem">
            <span>March 29, 2023</span>
          </a>

          <a routerLink="eCon5" routerLinkActive="active" class="mailItem">
            <span>March 15, 2023</span>
          </a>

          <!-- <div
            *ngFor="let item of eConnections; let i = index"
            class="mailItem"
          >
            <span (click)="getEmail(i)">{{ item.date }}</span>
          </div> -->

          <a routerLink="eCon6" routerLinkActive="active" class="mailItem">
            <span>March 7, 2023</span>
          </a>

        </div>

        <router-outlet #outlet="outlet"></router-outlet>
      </div>
      <div class="eConnectionForm" id="requestForm">
        <h3>eConnection Registration Form</h3>
        <form #userForm="ngForm" (ngSubmit)="submitRequest(userForm)">
          <div class="formGroup">
            <label>First Name*</label>
            <input
              type="text"
              required
              ngModel
              #FirstName="ngModel"
              name="FirstName"
            />
            <div class="required" *ngIf="FirstName.touched && !FirstName.valid">
              First Name is required
            </div>
          </div>
          <div class="formGroup">
            <label>Last Name*</label>
            <input
              type="text"
              required
              ngModel
              #LastName="ngModel"
              name="Last Name"
            />
            <div class="required" *ngIf="LastName.touched && !LastName.valid">
              Last Name is required
            </div>
          </div>
          <div class="formGroup">
            <label>Email Address*</label>
            <input
              type="email"
              required
              ngModel
              email
              #Email="ngModel"
              name="Email"
            />
            <div class="required" *ngIf="Email.touched && !Email.valid">
              Valid Email Address is required
            </div>
          </div>

          <button>Submit</button>
        </form>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
