<div id="footer">
<div class="footerContent">
<div class="contactInfo">
    <img src="../../assets/images/APRA LOGO White.svg" alt="" class="logo">
    <span>1520 Belle View Blvd - 3097
</span>
<span>  Alexandria, Virginia 22307</span>
<span>  United States of America</span>
<br>
<span>  P: +1 (703) 968-2772</span>
<br>
<span>E: kripli@apra.org</span>
<span>info@apra.org</span>
</div>

<div class="sitemap">
    <span>Site Map</span>
   <a routerLink="/About" routerLinkActive="active">What is APRA?</a>
        <a routerLink="/Board_of_Directors" routerLinkActive="active">APRA Board of Directors</a>
        <a routerLink="/Historic/HistoricHome" routerLinkActive="active">80 Years of Remanufaturing</a>
    

<a routerLink="/Membership" routerLinkActive="active">Membership</a>


    
        <a routerLink="/Reman_Connection" routerLinkActive="active">Reman Connection</a>
        <a routerLink="/eConnection_Archive" routerLinkActive="active">eConnection</a>
        <!-- <a routerLink="/first-component" routerLinkActive="active">Press Release</a> -->
    


<a routerLink="/Advertising" routerLinkActive="active">Advertising</a>
<a routerLink="/APRATech" routerLinkActive="active">APRA Tech</a>
<a routerLink="/Core_Finder" routerLinkActive="active">Core Finder</a>
<a routerLink="/HDRG" routerLinkActive="active">HDRG</a>


</div>
</div>
<hr>
</div>
