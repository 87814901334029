<img src="../../assets/images/40's/1940_Banner.jpg" alt="" class="banner">
<h1>1940's</h1>
<span>In 1941, APRA was established by a few remanufacturers and a small office in Los Angeles, California. Then called Automotive Parts Rebuilders Association, they quickly went to work to fight the Excise tax.</span>

<br>
<br>
<span>Click on the images to view the full documents.

</span>
<br>
<br>
<div class="docGrid">
<div class="document">
    <a href="../../assets/images/40's/APRA_OpeningLetter.pdf" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/40's/APRA_Letter.jpg" alt=""></a>
    <div class="docTitle">
        <span>Letter from the Co-Founder announcing the creation of APRA	</span>
    </div>
</div>

<div class="document">
    <a href="../../assets/images/40's/APRA_41_Bylaws.pdf" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/40's/APRA_41_Bylaws_Page_1.jpg" alt=""></a>
    <div class="docTitle">
        <span>1941 Bylaws
        </span>
    </div>
</div>

<div class="document">
    <a href="../../assets/images/40's/APRA_41_MemApp.pdf" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/40's/APRA_41_MemApp.jpg" alt=""></a>
    <div class="docTitle">
        <span>1941 Membership Application  	</span>
    </div>
</div>

<div class="document">
    <a href="../../assets/images/40's/1940_APRABoard.pdf" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/40's/1940_APRABoard.jpg" alt=""></a>
    <div class="docTitle">
        <span>The Host Committee of the 1948 Convetion</span>
    </div>
</div>
</div>


<p>Later that year, supported by the new association and her members, representatives from APRA traveled to Washington D.C. for a hearing held before Committee of Finance.

 <br>
 <br>

    They argued against the tax implementation on remanufactured auto parts, drawing a distinction between manufactured new auto parts and remanufactured used parts. The full manuscript for that meeting can be viewed below!</p>

<div class="docGrid">
    <div class="document">
        <a href="../../assets/images/40's/1940_APRAMem_Letters.pdf" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/40's/1940_APRAMem_Letters_Page_1.jpg" alt=""></a>
        <div class="docTitle">
            <span>Letters from APRA Members to Congress about the Excise Tax	</span>
        </div>
    </div>
    
    <div class="document">
        <a href="../../assets/images/40's/APRA_SenateHearingScript.pdf" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/40's/APRA_SenateHearingScript_Pag.jpg" alt=""></a>
        <div class="docTitle">
            <span>The full manuscript of the APRA Committee of Finance Meeting
            </span>
        </div>
    </div>
   
    </div>

<p>The first APRA Convention was held in 1947. Below are the Convention Book's from the 2nd Show in 1948, and the National Convention in 1949. 

</p>


    <div class="docGrid">
        <div class="document">
            <a href="../../assets/images/40's/1948_APRAConventionBook.pdf" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/40's/1948_APRAConventionBook_Cove.jpg" alt=""></a>
            <div class="docTitle">
                <span>1948</span>
            </div>
        </div>
        
        <div class="document">
            <a href="../../assets/images/40's/1949_APRAConventionBook.pdf" target="_blank" rel="noopener noreferrer"><img src="../../assets/images/40's/1949_APRAConventionBook_Cove.jpg" alt=""></a>
            <div class="docTitle">
                <span>1949</span>
            </div>
        </div>
       
        </div>