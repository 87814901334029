<style type="text/css">
    a.fr-green {
        color: green;
    }
    
    a.fr-strong {
        font-weight: 700;
    }
    
    a {
        color: inherit;
    }

</style>

<table align="center" style="border: 0px; border-collapse: collapse; empty-cells: show; max-width: 100%;" width="650px">
    <tbody>
        <tr style="user-select: none;">
            <td style="width: 25%;">
                <br>
            </td>
            <td style="font-size: 16px; font-family: sans-serif; width: 73.9804%;">June 2, 2023 &nbsp;
                <br>
            </td>
            <td colspan="2" style="min-width: 5px; border: 1px solid rgb(221, 221, 221); user-select: text; width: 0.9063%;">&nbsp;</td>
        </tr>
        <tr style="user-select: none;">
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td colspan="3" style="text-align: center; width: 74.8867%;">
                <hr style="clear: both; user-select: none; break-after: page;"><img style="text-align: center;" src="https://res.cloudinary.com/micronetonline/image/upload/c_limit,w_650,h_118/v1621882365/tenants/78d69308-ea4b-4397-80cf-05c95cee98af/898a2e32928544cf9f075511ff350e30/apra-econnection-logo-650w.png" alt="APRA E-Connection" width="650" height="118">
                <hr style="clear: both; user-select: none; break-after: page;">
            </td>
        </tr>
        <tr style="user-select: none;">
            <!-- News Stories Start HERE -->
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td style="text-align: left; width: 73.9804%;">
                <!-- News Stories Start HERE -->
                <!-- Heading -->
                <div style="padding: 20px 0px; text-align: center;">
                    <a href="https://www.armex.com/" rel="noopener noreferrer" style="color: inherit; user-select: auto;" target="_blank"><img style="width: 646px; height: 80.5733px;" src="https://res.cloudinary.com/micronetonline/image/upload/c_limit,w_485,h_60/v1621882466/tenants/78d69308-ea4b-4397-80cf-05c95cee98af/6a0dc1cb8fa24706876325db81920271/armex-ani-webbanners-485-x-6.gif" width="646" height="80.5733"></a></div>

                <table align="center" style="border: 0px; border-collapse: collapse; empty-cells: show; max-width: 100%;" width="650px">
                    <tbody>
                        <tr style="user-select: none;">
                            <td style="text-align: left; width: 72.6208%; background-color: rgb(255, 255, 255);">
                                <div style="background-color: #347aa3; margin-top: 25px; padding: 12px; text-align: left; font-size: 18px;">

                                    <p><span style="font-size: 24px;"><span style="color: rgb(255, 255, 255);">ETE Reman is partnering with Gauge Capital</span></span></p>
                                </div>

                                <p dir="ltr" style="line-height:1.3441203117370606;margin-left: 0.1766357421875pt;text-indent: 0.15456390380859375pt;margin-top:15.635986328125pt;margin-bottom:0pt;"><span style="font-size: 18px; font-family: Arial, Helvetica, sans-serif; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">Milwaukee, WI &ndash; Engine &amp; Transmission Exchange, Inc. (ETE REMAN, ETE or the &ldquo;Company&rdquo;) &nbsp;has announced a new partnership agreement with Gauge Capital of Southlake, Texas (Gauge) &nbsp;to recapitalize the Company. Founded in 1985 and headquartered in Milwaukee, WI, ETE &nbsp;REMAN is a leading independent aftermarket transmission remanufacturer and focuses on &nbsp;nondiscretionary automotive repair and replacements. ETE REMAN&rsquo;s primary business line is &nbsp;the remanufacture and sale of automatic transmissions. The Company has a proven track record &nbsp;and enjoys an industry‐leading reputation for delivering on quality and customer service.&nbsp;</span></p>

                                <p dir="ltr" style="line-height:1.344569492340088;margin-left: 0.35327911376953125pt;margin-right: 3.42041015625pt;text-indent: 0.3863983154296875pt;margin-top:15.430419921875pt;margin-bottom:0pt;"><span style="font-size: 18px;"><span style="font-family: Arial,Helvetica,sans-serif;"><span style="color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">&ldquo;We are excited to partner with Gauge and for the resources they add to ETE REMAN. Gauge&rsquo;s &nbsp;investment in the business will allow us to accelerate our growth and further invest in our &nbsp;remanufacturing, product development, product quality, and human capital,&rdquo; said CEO Noah &nbsp;Rickun. &ldquo;The Gauge team understands ETE&rsquo;s vision for the future and embraces our &nbsp;commitment to &lsquo;Build transmissions that last a lifetime&hellip;and relationships that last forever.&rsquo;&rdquo;&nbsp;</span></span></span></p>

                                <p dir="ltr" style="line-height:1.3442670822143554;margin-left: 0.3422393798828125pt;margin-right: 2.28857421875pt;text-indent: 0.3422393798828125pt;margin-top:15.42626953125pt;margin-bottom:0pt;"><span style="font-size: 18px;"><span style="font-family: Arial,Helvetica,sans-serif;"><span style="color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">ʺWe spent a long time searching for the right partner &ndash; one that is good for our employees, our &nbsp;customers, and for ETE,&rdquo; said founder Sam Loshak. &ldquo;I am proud to say that we found that &nbsp;partner in Gauge. They share our core values, and their investment will help us capture a larger &nbsp;share of the transmission market.&rdquo;&nbsp;</span></span></span></p>

                                <p dir="ltr" style="line-height: 1.34185; margin-right: 3.08844pt; text-indent: -0.30912pt; margin-top: 15.5491pt; margin-bottom: 0pt; padding: 0pt 0pt 0pt 0.30912pt;"><span style="font-size: 18px;"><span style="font-family: Arial,Helvetica,sans-serif;"><span style="color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">James Jackson, Partner at Gauge Capital, added, &ldquo;We couldn&rsquo;t be happier to partner with ETE &nbsp;REMAN and to work alongside Noah and his team to realize our shared vision for the future of &nbsp;the Company. ETE&rsquo;s consistent focus on quality, dependability, and the importance of &nbsp;relationships helped them create an amazing business. We look forward to maintaining those &nbsp;key values while providing additional resources to further accelerate their growth.&rdquo;&nbsp;</span></span></span></p>

                                <p dir="ltr" style="line-height: 1.33642; margin-right: 18.7497pt; text-indent: 0.30912pt; margin-top: 15.5713pt; margin-bottom: 0pt;"><span style="font-family: Arial, Helvetica, sans-serif; font-size: 18px;"><span style="color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">As part of the partnership agreement, Noah Rickun and Sam Loshak will be joined by James &nbsp;Jackson and Kevin Rafferty from Gauge Capital on the Company&rsquo;s Board of Directors.</span></span></p>
                                <div align="justify" style="color: rgb(0, 0, 0); font-family: Arial, Verdana, Helvetica, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; text-align: justify;">
                                    <br>
                                </div>
                                <br>
                                <hr>
                                <div style="text-align: center;">
                                    <a href="http://coresupply.com/" rel="noopener noreferrer" style=" user-select: auto;" target="_blank"><img style="position: relative; max-width: 100%; cursor: pointer; padding: 0px 1px;" src="https://res.cloudinary.com/micronetonline/image/upload/c_limit,w_1320,h_360/v1621882787/tenants/78d69308-ea4b-4397-80cf-05c95cee98af/69f49e7dcf7a48ca9347e415cb3f42fc/RAS-Banner-2021.jpg" width="633" height="174"></a></div>
                                <hr>
                                <div style="background-color: #347aa3; margin-top: 25px; padding: 12px; text-align: left; font-size: 18px;">

                                    <p><span style="font-size: 30px;"><span style="color: rgb(255, 255, 255);">INA PAACE Automechanika Mexico 25th Anniversary</span></span></p>
                                </div>
                                <br>
                                <div align="justify" style="color: rgb(0, 0, 0); font-family: Arial, Verdana, Helvetica, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; text-align: justify;">
                                    <br>
                                </div>
                                <div align="justify" style="color: rgb(0, 0, 0); font-family: Arial, Verdana, Helvetica, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: rgb(255, 255, 255); text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; text-align: justify;"><img src="https://res.cloudinary.com/micronetonline/image/upload/c_limit,w_767,h_1044/v1683481106/tenants/78d69308-ea4b-4397-80cf-05c95cee98af/c401bd3d25474f1788a24aa68e24f214/inapaace25.jpg" width="636" height="865">
                                    <br>
                                </div>
                                <br>
                                <br>
                                <hr>
                                <br>
                                <br>
                                <div style="text-align: center;">
                                    <a href="https://www.oec2021ads.com/autoparts" style="color: inherit; user-select: auto;"><img style="position: relative; max-width: calc(100% - 10px); cursor: pointer; padding: 0px 1px; display: inline-block; vertical-align: bottom; margin-right: 5px; margin-left: 5px; text-align: center;" src="https://automotivepartsremanufacturersassociations.growthzoneapp.com/ap/CloudFile/Download/P7lnyAVL" width="626" height="174"></a></div>
                                <br>

                                <table style="font-family: sans-serif; color: rgb(51, 51, 51); margin-left: calc(0%); width: 100%;" width="100%">
                                    <tbody>
                                        <tr style="user-select: none;">
                                            <td colspan="2" style="padding: 10px; font-size: 20px; font-family: sans-serif; text-align: center; color: rgb(255, 255, 255); background-color: rgb(52, 122, 163); width: 72.1677%;"><span style="font-size: 18px;"><strong style="font-weight: 700;">INDUSTRY CALENDAR - UPCOMING EVENTS</strong></span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><a href="https://www.rematec.com/amsterdam/about-the-exhibition/" rel="noopener noreferrer" target="_blank"><span style="font-size: 18px;">Rematec 2023</span></a></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">27-29 June 2023 &nbsp;<br>&nbsp;Amsterdam, Netherlands</span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><a href="https://ina-paace-automechanika-mexico-city.us.messefrankfurt.com/mexico-city/en.html" rel="noopener noreferrer" target="_blank"><span style="font-size: 18px;">INA/PAACE Automechanika</span></a></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">12-14 July 2023 &nbsp;<br>&nbsp;Mexico City, Mexico</span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><span style="font-size: 18px;">BigR Symposium</span></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">30 October 2023 &nbsp;<br>&nbsp;Las Vegas NV, USA</span></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p style="font-family: sans-serif; font-size: 14px; text-align: center;"><span style="font-size: 18px;">Have information you&#39;d like to share with the industry? Contact Us!<br>Want to advertise in this and other APRA publications? <a href="https://apra.org/#/Advertising" style="color: inherit; user-select: auto;" target="_blank">Click Here</a>&nbsp;</span>
                                    <br><span style="font-size: 18px;">Automotive Parts Remanufacturers Association<br>1520 Belle View Blvd - 3097<br>Alexandria, Virginia 22307-6531<br>P: (703) 968-2772<br>info@apra.org | <a href="https://apra.org" style="color: inherit; user-select: auto;">www.apra.org</a></span></p>
                            </td>
                        </tr>
                        <tr style="user-select: none;">
                            <td style="text-align: left; width: 72.6208%;"><span style="font-size: 18px;"><br></span></td>
                            <td style="min-width: 5px; border: 1px solid rgb(221, 221, 221); user-select: text; width: 0.9063%;"><span style="font-size: 18px;">&nbsp;</span></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>