import { Injectable } from '@angular/core';
import { BehaviorSubject, from, observable, Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {
events: any;


private animationTrigger = new BehaviorSubject<string>("");
currentAnimation = this.animationTrigger.asObservable();


// const mainCalendar = new Observable((observable) => {
//   let watchId: number
// });



  constructor(
    private http: HttpClient
    ) { }

 
  uncheckAll() {
    var checks: any = document.querySelectorAll('input[type="checkbox"]');
    for(var i =0; i< checks.length;i++){
        var check = checks[i];
        if(!check.disabled){
            check.checked = false;
        }
    }
}

getEvents(){

 
  
  return (this.http.get("https://apra.org:3000/api/getEvents"));

}


getWebsiteEvents(){

  
  return (this.http.get("https://apra.org:3000/api/getWebsiteEvents"));

}

getEventDetails(id){
  return this.http.get("https://apra.org:3000/api/getEventDetails" , {
    params: { eventId: id }
});
  
}

getEventDate(id){
  return this.http.get("https://apra.org:3000/api/getEventDate" , {
    params: { eventId: id }
});
  
}

getEventFiles(id){
  return this.http.get("https://apra.org:3000/api/getEventFiles" , {
    params: { eventId: id }
});
  
}

getFileShare(id){
  return this.http.get("https://apra.org:3000/api/getFileShare" , {
    params: { fileId: id }
});
  
}

postCoreRequest(req: string, type: string){
  console.log(req, type)
 return this.http.post("https://apra.org:3000/api/postRequest" , {
    params: {description: req, requestType: type}
});
}


getFolder(id){
  return this.http.get("https://apra.org:3000/api/folderContents" , {
    params: { folderId: id }
});
  
}

downloadFile(id){
  return this.http.get("https://apra.org:3000/api/downloadFile" , {
    params: { fileId: id }
});
  
}

getFileMeta(id){
  return this.http.get("https://apra.org:3000/api/getFileMeta" , {
    params: { fileId: id }
});
  
}

geteConnection(){
  return this.http.get("http://localhost:5000/api/eConnection")
}
  
geteMail(id){
  return this.http.get("http://localhost:5000/api/eMail" , {
    params: { fileId: id }
});
  
}

}
