<div class="aboutHeader" id="firstHeader">
    <H1 class="headerTopLine">MEMBERSHIP</H1>
    <h1 class="headerBottomLine">BENEFITS</h1>
    </div>
    <a href="http://automotivepartsremanufacturersassociations.growthzoneapp.com/applicationtojoin" class="joinButton">Join Today!</a>
<div class="contentSection">
    <p class="subHeader">Whether you’re interested in becoming more involved in your industry or looking to save on a variety of programs to help you run your business more cost effectively, APRA membership can help. APRA’s mission is to address the needs of the automotive and truck parts remanufacturing industry and to serve our members in four primary ways:
    </p>

    <div class="subBenifits">
<div class="benifitBlock">
    <i class="fas fa-info"></i>
    <p>A wide range industry and technical resources.</p>
   
</div>
<div class="benifitBlock">
    <i class="fas fa-handshake"></i>
    <p>   Events and Networking Opertunities    </p>
</div>
<div class="benifitBlock">
    <i class="fas fa-balance-scale-right"></i>
<p>legislative, legal and advocacy at various levels of government</p>

</div>

    </div>
</div>
    <div class="aboutHeader">
        <H1 class="headerTopLine">APRA</H1>
        <h1 class="headerBottomLine">RESOURCES</h1>
        </div>
<div class="contentSection">
        <H2>
            Print
        </H2>
<ul>
    <li><b>Reman Connection:</b> The new magazine replacing the leading Global Connections of the remanufacturing industry with current news features, new product updates, technical information and company features. Monthly circulation: 10,000+. Members receive advertising discounts on standard display rates. You can view the Reman Connection Archive by clicking   <a routerLink="/Reman_Connection" routerLinkActive="active">here</a>. 
    </li>
    <li><b>APRA Membership Directory:</b> Published annually, it is your resource to network with more than 1000 worldwide APRA member companies.
    </li>
    <li><b>Global Sourcing Directory:</b> Biennial listing of APRA US companies that are interested in exporting remanufactured products, cores, components, equipment, etc. to emerging world markets. Distributed at PAACE/Automechanika, Big R/ReMaTecUSA Show, ReMaTec and other selected trade shows as well as US Trade Consuls around the world.
    </li>
    <li><b>Core Suppliers Buyers Guide:</b> Annual publication in the December Global Connection newspaper listing all APRA Core Supplier Members worldwide that can help you source parts.
    </li>
    <li><b>Lester Catalog:</b> Receive a 20% discount on all printed catalogs from Lester Catalog as an APRA member.
    </li>
    <li><b>Remanufacturing Automotive Mechatronics & Electronics:</b> Fernand Weiland, Co-chairman of APRA’s Electronics & Mechatronics Division, organized and edited this book to inform the remanufacturing community on the upcoming product changes and encourage them to embrace these new technologies.
    </li>
</ul>
        <H2>
            Online
        </H2>

        <ul>
<li><b>e-Connection:</b> APRA’s electronic newsletter is sent weekly to 8,000+ people worldwide to keep them up-to-date on the latest fast-breaking developments in the remanufacturing industry. Includes new product releases from suppliers.
</li>
<li><b>APRA Press Release:</b> Quick information that brings you breaking news about the industry, association, and members all in one place. 
</li>
<!-- <li><b>www.buyreman.com :</b> Buyreman Remanufacturing Marketplace features a wide range of products and services exclusively for the automotive and truck parts remanufacturing industry supporting APRA’s members and their customers in their purchasing decisions.
</li> -->
<li><b>APRA Europe :</b> Dedicated to reporting remanufacturing news in Europe.
</li>
<!-- <li><b>www.bigrshow.com :</b> This website allows exhibitors to promote their business beyond the annual International Big R Show.
</li> -->
<li><b>Job Listings:</b> APRA members may post employment opportunities at http://jobs.mema.org.
</li>

<li><b>www.kwikfinder.net :</b> Through a special alliance with Lester Catalog we are able to bring you their online catalog at the special APRA member discounted price of $100 off the standard rates. This gives you instant access to all the specifications and bill of materials as well as pictures for most alternators and starters. CD and DVD version discounts are also available. 
    <!-- CLICK HERE for an order form or visit www.kwikfinder.net. -->
</li>
        </ul>
</div>

<div class="aboutHeader">
    <H1 class="headerTopLine">MEMBER</H1>
    <h1 class="headerBottomLine">SAVINGS</h1>
    </div>

    <div class="contentSection savingsContent">

        <h2 class="savingsLink">MEMBERS CAN SAVE AN AVERAGE OF $14,000 EVERY YEAR!</h2>
        <br>

        <div class="upsSavings">
        <p>
            The world moves fast – and your CBIA UPS® Savings Program has the tools you need to help your 
            small business move even faster. We’re here to help you push envelopes and ride the edge of what’s possible. 
            We’ll be right by your side with everything you need to keep blazing forward.
        </p>
        <p>
            Let UPS® help make your business unstoppable. Members enjoy the following savings every time you ship:
        </p>
        <ul>
            <li>65% off Domestic Next Day</li>
            <li>42% off Ground</li>
            <li>65% off International</li>
        </ul>
        <p>
            <a href="https://www.savewithups.com/ctbia" target="_blank">Sign up for a new account</a>
            <br>
            Or if you already have a UPS account, simply re-enroll and apply our member-only savings to your existing account.
        </p>
        </div>
        <br>

        <SPAN>(CLICK THE LOGOS FOR MORE INFORMATION)</SPAN>

        <div class="savingsBlock">
            <div *ngFor="let service of savings; let i = index" class="savingType">
                <h3>{{service.type}}</h3>
                <div class="logos">
                <div *ngFor="let logo of service.logos; let j = index" class="logo">
                    <a href={{service.links[j]}} target="_blank" rel="noopener noreferrer">
                    <img src="../../assets/images/savingsLogos/{{logo}}" alt="">
                    </a>
                </div>
                </div>
                <p>{{service.message}}</p>
            </div>
        </div>

        <h3 class="savingsLink">Visit <a href="http://apra.savings4members.com/" target="_blank" rel="noopener noreferrer">SAVINGS 4 MEMBERS</a> for more information and <a href="https://go.risewithaurora.com/partner-apra" target="_blank" rel="noopener noreferrer">AURA PAYMENTS</a> for your credit card processing needs</h3>

    </div>

        <div class="aboutHeader">
            <H1 class="headerTopLine">APRA</H1>
            <h1 class="headerBottomLine">EVENTS</h1>
            </div>
    <div class="memberDiscountHeader">
        <span class="memberDiscount">SPECIAL MEMBER DISCOUNTS!</span>
       <p>APRA member companies receive substantially discounted booth rates at the events shown below.</p>
    </div>

    <div class="eventGrid">
        <img src="../../assets/images/Logos/2019_big-r_logo_250w.png"> 
        <div></div>
        <img src="../../assets/images/Logos/paace_mexico.jpg">
        <div></div>
        <img src="../../assets/images/Logos/remattec.png">
    </div>

    <app-footer></app-footer>
