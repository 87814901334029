import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { DOCUMENT } from '@angular/common';
import {SessionDataService} from '../session-data.service'
import { from, Observable } from 'rxjs';
import { animation } from '@angular/animations';
import { $ } from 'protractor';
import { analyzeAndValidateNgModules } from '@angular/compiler';
// import { reverse } from 'dns';



gsap.registerPlugin(ScrollTrigger);


@Component({
  selector: 'Home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  Months=[]

  private events = [];
  @ViewChild('hello', { static: true }) hello: ElementRef<HTMLDivElement>;

  constructor(@Inject(DOCUMENT) private document: Document, private data: SessionDataService) { 


  }

  animationTrigger:string;
  public calendar = [];
  adImage: string;
  adUrl: string;

  ngOnInit(): void {
    // this.data.currentAnimation.subscribe(animation=> this.animationTrigger = animation)
this.data.getFolder(326879).subscribe((res)=>{
  let folder: any = res;
  let shared = [];
  folder.forEach(element => {
    if (element.IsShared){
      shared.push(element)
    }
  });

  let randNumb = Math.floor(Math.random() * shared.length)
console.log(randNumb)
  this.data.getFileShare(shared[randNumb].Id).subscribe((res) =>{
  let sharedfile: any = res
this.adImage = sharedfile.PublicUrl;
  })

  this.data.getFileMeta(shared[randNumb].Id).subscribe((res) =>{
  let sharedMeta: any = res
this.adUrl = sharedMeta.Description;
  })

})


this.Months=['January','February','March','April','May','June','July','August','September','October','November','December']

    this.sectionOneScrollAnimations();
    this.data.uncheckAll();
   this.data.getEvents().subscribe((res: [])=>{
     this.calendar = res
     this.calendar.forEach(event =>{
       let StartDate = new Date(event.StartDateLocal)
       let EndDate = new Date(event.EndDateLocal)
       event.StartDate = StartDate.getDate()
       event.StartDateLocal = StartDate.getMonth()
       event.EndDate = EndDate.getDate()
       event.EndDateLocal = EndDate.getMonth()
       event.ParentListId = "-"
       
       if(event.StartDate == event.EndDate && event.StartDateLocal == event.EndDateLocal)
       {
        event.EndDate = null
        event.EndDateLocal = null
        event.ParentListId = ""       }
     }
      )
   })

  }
  ngOnDestroy():void{
    // window.scrollTo(0,0);
    ScrollTrigger.getById("trigger1").kill(true);
  }


animate(direction):any{
  if(direction == "scroll"){
    var element = document.getElementById("mainLogo");
    element.classList.add("removeTransition");
    element = document.getElementById("greenBackground");
    element.classList.add("removeTransition");
    element = document.getElementById("mainHomeBlock");
    element.classList.add("removeTransition");
}else if(direction == "scrollBack"){
  var removeTransition = document.getElementsByClassName("removeTransition"); 
 
  for (var i = 0; removeTransition.length > 0; i++) {
    removeTransition[0].classList.remove("removeTransition")
    
  }

}

}
  
    sectionOneScrollAnimations(): void {


    gsap.to(this.hello.nativeElement, {
        scrollTrigger: {
          id: "trigger1",
          once: false,
          trigger: this.hello.nativeElement,
          
        onEnter: () => this.animate("scroll"),
        onLeaveBack: () => this.animate("scrollBack"),
          start: 'top',
        }
      });
    }
}



// 326879