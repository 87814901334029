<div class="aboutHeader" id="firstHeader">
  <H1 class="headerTopLine">PRESS</H1>
  <h1 class="headerBottomLine">RELEASES</h1>
</div>
<div class="contentSection">

  <div class="pressHeader">
    <h2>Heavy Duty Aftermarket Week Marks
      Record Year in Texas</h2>
    <span>January, 2023</span>
    </div>

<p> MOKENA, IL (JANUARY 25, 2023)—More than 2,600 heavy duty aftermarket professionals gathered January 16-19, 2023 at Heavy Duty Aftermarket Week (HDAW)—the largest North American gathering of heavy-duty aftermarket professionals in the industry, for four days of expert-led education, invaluable networking opportunities, personalized business meetings and a Product Expo featuring the latest products. At the event, the industry also honored Stu MacKay, President Emeritus, MacKay & Company, as the 2023 Heavy Duty Aftermarket Industry Hall of Fame recipient and Weldon Parts as the Distributor of the Year. 
</p><p>
“We are truly thankful for the amazing turnout at HDAW’23,” said Bill Betts, President and CEO, Betts Trucks Parts & Service, and the distributor co-chair for HDAW‘23. “The excitement, starting from our opening receptions to the Product Expo Floor and education sessions, was obvious. It was also incredibly important that we recognized the people and organizations that have made a lasting impact on our industry like Stu and Weldon Parts – it’s because of people like them that our industry continues to grow.”
</p><p>
The 2023 annual meeting featured a 59,000+ square foot, sold-out Product Expo floor with 300+ suppliers, including more than 40 first-time exhibitors, showcasing the latest HD aftermarket equipment, products, tools, technology and more. Produced by a joint operating committee of industry leaders from 12 distinguished industry associations, the 2023 event saw a 27% increase in attendance from 2022 and its largest distributor showing in event history.
</p><p>
This year boasted the theme of "Shift, Adapt, Grow" interwoven throughout the program, with a highly regarded keynote by Brian Kight, who offered leadership insights and a refreshing perspective on organizational development. There were additional opportunities for distributors and suppliers to meet through their popular One-on-One Business Meetings program and peer-to-peer roundtable discussions. 
</p><p>
“There is a reason why HDAW’23 sold-out of supplier meeting rooms and had the biggest One-on-One program ever – our industry has been eager to reconnect and get ahead of this changing landscape” said Brian Rieger, Vice President of North American Sales, ConMet, and the supplier co-chair for HDAW‘23. “It was important for us to provide the industry a first-look not only at the top brands and the latest innovations but offer a program that hit on topics impacting their businesses now and, in the future – like conversations on 'HD Powertrains of the Future' and 'The Future of Diagnostics for HD/EV Vehicles.'”
</p><p>
The program also highlighted an industry hot topic with a discussion on Right to Repair, which featured an interactive conversation with Ann Wilson, SVP of Government Affairs at MEMA and Lisa Foshee, SVP of Government Affairs at Auto Care Association, moderated by Marc Karon, President, Total Truck Parts.
</p><p>
In the discussion, Foshee and Wilson spoke to the audience about the critical issue of Right to Repair legislation and how it is impacting the industry as a new legislative year kicks off. Audience members had the opportunity to ask questions and dive further into ways the industry could get involved. The passionate discussion ended with a call to be part of a movement to reach out to Congress by visiting www.repairact.com.
</p><p>
HDAW‘23 brings together executives and managers from across the industry, including distributors, suppliers, service providers, industry experts and thought leaders and more. Planning for the 2024 event, taking place January 22- 25 at the Gaylord Texan Resort & Convention Center, is already well underway. For more information, visit, visit <a href="https://www.hdaw.org" target="_blank" rel="noopener noreferrer">www.hdaw.org</a> and connect with HDAW online on Twitter, Facebook and LinkedIn. 
</p>


  <div class="pressHeader">
    <h2>Reman Day 2022 Celebrated in A Big Way in Chicago Area</h2>
    <span>April, 2022</span>
  </div>

  <p>
    Reman Day 2022 was April 14. To celebrate Reman Day, two dozen members of
    the remanufacturing industries convened today at ZF Group’s facility in
    Vernon Hills, Illinois. At the invitation of ZF Reman Engineering Leader
    Jeffrey Stukenborg, remanufacturing industry members were introduced to ZF’s
    wide range of remanufacturing capabilities of everything from transmissions
    and automotive assemblies to massive wind turbine gears.
  </p>
  <p>
    Started by the Remanufacturing Industries Council in 2017, Global Reman
    Day was established to advance the remanufacturing industry through
    remanufacturer-hosted events and workforce development initiatives. Products
    that can be remanufactured include, of course, toner and ink jet cartridges,
    but also all kinds of autos and auto parts, industrial machinery, small
    appliances, medical devices, production engines and even airplanes and
    aeronautical equipment.
  </p>
  <p>
    Representatives from Caterpillar, Navistar, Allen Woods &amp; Associates,
    Armex, AMBAC Manufacturing and Clover Imaging Group were in attendance.
    Industry trade associations were also represented, including the Automotive
    Parts Remanufacturing Association (APRA), MERA the Association for
    Sustainable Manufacturing (part of the Motor &amp; Equipment Manufacturing
    Association) and the International Imaging Technology Council (Int’l ITC).
    “It was great to have our customers and reman organizations visit and tour
    our remanufacturing operation in Vernon Hills on Reman Day providing
    awareness of how remanufacturing can be a great sustainability tool in
    protecting the environment”, said Stukenborg said.
  </p>
  <p>
    Each of the association leaders introduced their respective associations and
    members who were present. Tricia Judge, executive director of the Int’l ITC,
    introduced the Clover Imaging Group representatives, and shared some facts
    about imaging supplies. “More than 375 million cartridges are thrown into
    landfills every year. That’s more than one million every day. And it’s a
    shame because 90 percent of each cartridge could be reused through
    remanufacturing.
  </p>
  <p>
    Joe Kripli, president of APRA, said “Of all automotive manufactured
    components, only two percent are remanufactured. If your company is planning
    on being carbon neutral in the near future, you will need remanufacturing to
    get you there.”
  </p>
  <p>
    ZF Group is striving to be climate-neutral by 2040. To achieve this, the
    company is acting in many different areas. For example, via power purchase
    agreements concluded in 2021 with producers of wind and solar energy, ZF
    plants in Germany will be supplied with up to 210 gigawatt hours of green
    electricity in each of the years 2022 to 2025. The agreed volumes, which
    correspond to the electricity consumption of 72,000 homes, will reduce CO2
    emissions by 80,000 tons annually.
  </p>
  <p>
    The day’s events were capped off with a tour of ZF Group’s
    290,000-square-foot remanufacturing facility. ZF Group started by
    remanufacturing automotive parts for the Chicago Transit Authority and has
    evolved into a global remanufacturer of heavy industrial equipment,
    transmissions and other automotive parts and blades for use in wind
    turbines.
  </p>
  <p>
    Remanufacturers are having a tremendous positive impact on the environment.
    Remanufacturing is defined as a comprehensive and rigorous industrial
    process by which a previously sold, leased, used, worn, remanufactured, or
    non-functional product or part is returned to a like-new, same-as-when-new,
    or better-than-when-new condition, from both a quality and performance
    perspective, through a controlled, reproducible and sustainable process.
  </p>
  <p>
    A 2012 U.S. International Trade Commission (ITC) report found that
    remanufacturers support at least 180,000 full-time jobs in the U.S. Further,
    the ITC report states that production of remanufactured goods in the U.S.
    increased by 15 percent from 2009 to 2011 and exports totaled $11.2 billion
    annually.
  </p>
  <p>
    Remanufacturing has been recognized by leading universities, research
    institutions, and manufacturers in the United States as good for the
    environment for its ability to divert end-of- life products from landfills.
  </p>
  <p>
    By encouraging businesses, state agencies, nonprofit organizations, schools
    and individuals to celebrate Reman Day 2022, we can further promote
    remanufacturing as environmentally friendly and economically smart.
  </p>
  <p>
    To focus the nation’s attention on the benefits of remanufacturing,
    businesses, educational institutions, and nonprofit organizations have
    joined together to celebrate Reman Day and are encouraging their colleagues,
    friends and families, and their communities to learn more about the benefits
    of remanufactured products.
  </p>

  <hr />

  <div class="pressHeader">
    <h2>J&amp;N Electric Introduces Quality Aftermarket Start/Stop Starters</h2>
    <span>March 7, 2022</span>
  </div>

  <p>
    --high-tech aftermarket starters now available for more than 20+ major OEM
    auto brands
  </p>
  <br />

  <p>
    BLAINE, MN <a href="https://www.jnelectric.com/">J&amp;N Electric</a>, the
    rotating electrical division of&nbsp;<a
      href="https://arrowheadepinc.com/"
      style="text-decoration: none"
      >Arrowhead Engineered Products</a
    >,&nbsp;now offers high quality aftermarket replacement
    &ldquo;start/stop&rdquo; starter motors for newer vehicles. The
    &ldquo;start-stop&rdquo; technology exhibited in many automobile and light
    truck engines built since 2012 improves fuel economy by 3 to 5% and up to 7%
    for some hybrid vehicles. The advanced technology also lessens on carbon
    emissions by automatically shutting down and restarting a vehicle&rsquo;s
    internal combustion engine to reduce the engine&rsquo;s idling time. &nbsp;
  </p>
  <br />

  <p>
    The new technology however puts more &ldquo;wear and tear&rdquo; on the
    starters because they are constantly starting and stopping as the name of
    the technology implies. &nbsp;&ldquo;Now, having a reliable aftermarket
    start/stop replacement to expensive OEM start/stop starters represents a
    paradigm shift in the modern world of automotive repair. &nbsp;No longer
    will owners be dependent on their OE for such specialized replacement parts.
    &nbsp;The world-class J&amp;N replacement starter motors will be available
    for over 20 major brands of automobiles at much more competitive
    prices,&rdquo; says Craig Smith, Director of New Product Development,
    NA,&nbsp;Arrowhead Engineered Products. &nbsp;
  </p>
  <br />

  <p>
    The new replacement start/stop starters are designed and tested to
    accommodate over 200,000 lifetime starts proving their durability and
    quality. &nbsp;The J&amp;N motors replace Bosch or Denso start/stop motors
    for the following brands of automobiles: Audi, BMW, Buick, Cadillac,
    Chrysler, Ford, General Motors, Honda, Hyundai, Jeep, Kia, Mercedes-Benz,
    Mini, Porsche, Range Rover, Toyota, Volkswagen and more. With multiple
    distribution centers across North America, customers can expect the quick
    deliveries that J&amp;N is known for.&nbsp;
  </p>

  <hr />
  <br />

  <div class="pressHeader">
    <h2>Donald Bigler Celebrates 90th Birthday</h2>
    <span>March 1, 2022</span>
  </div>
  <p>
    <b>DAPHNE, ALABAMA:</b> Donald Bigler, co-founder and former CEO of BBB
    Industries, LLC (BBB), will celebrate his 90th birthday on March 2, 2022.
  </p>

  <p>
    As one of BBB’s three founders, under Bigler’s leadership, BBB grew from a
    small, regional sustainable manufacturer of rotating electrical parts to a
    global, industry-leading sustainable manufacturer of rotating electrical
    parts, hydraulic and air disc brake calipers, power steering products and
    turbochargers.
  </p>
  <p>
    “It has been an honor to participate in BBB’s growth the past 35 years,”
    Bigler said. “Because of our extraordinary people, I have great confidence
    in BBB’s future.”
  </p>
  <p>
    “Don’s 90th birthday is a day that is definitely worthy of a celebration,”
    said BBB’s CEO, Duncan Gillis. “I have long considered it a privilege to
    work alongside Don. His values and commitment helped lay BBB’s foundation,
    and thanks to his extraordinary vision, BBB is well positioned for future
    success.
  </p>
  <p>
    Today, Bigler is a daily presence at BBB’s headquarters, and he serves as
    BBB’s Chairman Emeritus and advisor to the CEO.
  </p>
</div>
