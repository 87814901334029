
<div id="greyHeader"  #greyHeader></div>


<div class="logoBox" #logoBox>
    <a routerLink="/Home" routerLinkActive="active"><img src="../../assets/images/APRA LOGO White.svg" class="logo" #logo></a>
</div>

    <div class="header">
    <span><a href="https://apraeurope.org/">Visit APRA Europe</a></span>
<span>Call Us: 703-968-2772</span>
        <a href="https://twitter.com/buyreman" class="socialIcons"><i class="fab fa-twitter-square fa-3x"></i></a>
        <a href="https://www.facebook.com/apra.org" class="socialIcons"><i class="fab fa-facebook-square fa-3x"></i></a>
        <a href="https://www.linkedin.com/company/automotive-parts-remanufacturers-association/about/" class="socialIcons"><i class="fab fa-linkedin fa-3x"></i></a>

    <a href="https://automotivepartsremanufacturersassociations.growthzoneapp.com/MIC/8993204/8130419/#/InfoHub" class="signIn">Sign In</a>
<div  class="menuButton">
    <input type="checkbox" class="mainInput">
    Menu
<i class="fas fa-bars"></i>
<ul id="menu">
    <li>
        <span>About</span>
        <ul class="subMenu">
            <input type="checkbox" class="subMenuCheck">
            <i class="fas fa-caret-right"></i>
            <li><a routerLink="/About" routerLinkActive="active"><div class="menuRoute">What is APRA?</div></a></li>
            <li><a routerLink="/Board_of_Directors" routerLinkActive="active"><div class="menuRoute">APRA Board of Directors</div></a></li>
            <li><a routerLink="/Historic/HistoricHome" routerLinkActive="active"><div class="menuRoute">80 Years of Remanufacturing</div></a></li>
        </ul>
    </li>
    <li><a routerLink="/Membership" routerLinkActive="active"><div class="menuRoute">Membership</div></a></li>
    <li>
        <span>News</span>
        <ul class="subMenu">
            <input type="checkbox" class="subMenuCheck">
            <i class="fas fa-caret-right"></i>
            <li><a routerLink="/Reman_Connection" routerLinkActive="active"><div class="menuRoute">Reman Connection</div></a></li>
            <li><a routerLink="/eConnection_Archive" routerLinkActive="active"><div class="menuRoute">eConnection</div></a></li>
            <li><a routerLink="/press_Release" routerLinkActive="active"><div class="menuRoute">Press Release</div></a></li>
            <!-- <li><a routerLink="/first-component" routerLinkActive="active"><div class="menuRoute">Press Release</div></a></li> -->
        </ul>
    </li>
    <li>
           <span>Events</span>
        <ul class="subMenu" >
            
             <input type="checkbox" class="subMenuCheck">
            <i class="fas fa-caret-right"></i>
            <li *ngFor="let event of events" (click)="eventRouter(event.Name, event.Id)">
                <a routerLinkActive="active">
                    <div class="menuRoute">{{event.Name}}</div>
                </a></li> 
           
        </ul>
    </li>
    <li><a routerLink="/Advertising" routerLinkActive="active"><div class="menuRoute">Advertising</div></a></li>
    <li><a routerLink="/APRATech" routerLinkActive="active"><div class="menuRoute">APRA Tech</div></a></li>
    <li><a routerLink="/Core_Finder" routerLinkActive="active"><div class="menuRoute">Core Finder</div></a></li>
    <li><a routerLink="/HDRG" routerLinkActive="active"><div class="menuRoute">HDRG</div></a></li>
</ul>   
</div>

</div>

