<style type="text/css">
    a.fr-green {
        color: green;
    }
    
    a.fr-strong {
        font-weight: 700;
    }
    
    a {
        color: inherit;
    }

</style>

<table align="center" style="border: 0px; border-collapse: collapse; empty-cells: show; max-width: 100%;" width="650px">
    <tbody>
        <tr style="user-select: none;">
            <td style="width: 25%;">
                <br>
            </td>
            <td style="font-size: 16px; font-family: sans-serif; width: 73.9804%;">March 15, 2023 &nbsp;
                <br><strong><span style="color: rgb(184, 49, 47);">Corrected Dates</span></strong></td>
            <td colspan="2" style="min-width: 5px; border: 1px solid rgb(221, 221, 221); user-select: text; width: 0.9063%;">&nbsp;</td>
        </tr>
        <tr style="user-select: none;">
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td colspan="3" style="text-align: center; width: 74.8867%;">
                <hr style="clear: both; user-select: none; break-after: page;"><img style="text-align: center;" src="https://res.cloudinary.com/micronetonline/image/upload/c_limit,w_650,h_118/v1621882365/tenants/78d69308-ea4b-4397-80cf-05c95cee98af/898a2e32928544cf9f075511ff350e30/apra-econnection-logo-650w.png" alt="APRA E-Connection" width="650" height="118">
                <hr style="clear: both; user-select: none; break-after: page;">
            </td>
        </tr>
        <tr style="user-select: none;">
            <!-- News Stories Start HERE -->
            <td style="width: 25.0000%;">
                <br>
            </td>
            <td style="text-align: left; width: 73.9804%;">
                <!-- News Stories Start HERE -->
                <!-- Heading -->
                <div style="padding: 20px 0px; text-align: center;">
                    <a href="https://www.armex.com/" rel="noopener noreferrer" style="color: inherit; user-select: auto;" target="_blank"><img style="width: 646px; height: 80.5733px;" src="https://res.cloudinary.com/micronetonline/image/upload/c_limit,w_485,h_60/v1621882466/tenants/78d69308-ea4b-4397-80cf-05c95cee98af/6a0dc1cb8fa24706876325db81920271/armex-ani-webbanners-485-x-6.gif" width="646" height="80.5733"></a></div>

                <table align="center" style="border: 0px; border-collapse: collapse; empty-cells: show; max-width: 100%;" width="650px">
                    <tbody>
                        <tr style="user-select: none;">
                            <td style="text-align: left; width: 72.6208%; background-color: rgb(255, 255, 255);">
                                <div style="background-color: #347aa3; margin-top: 25px; padding: 12px; text-align: left; font-size: 18px;">

                                    <p dir="ltr" style="line-height: 1.8; margin-top: 0pt; margin-bottom: 0pt;"><span style="color: rgb(255, 255, 255); font-size: 30px;"><span style="font-family: Calibri, sans-serif; background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><strong><meta charset="utf-8"></strong></span></span></p>

                                    <p><span style="font-size: 30px;"><span style="color: rgb(255, 255, 255);">Online Symposium on Innovation and&nbsp;</span></span><span style="color: rgb(255, 255, 255); font-size: 30px;">Automation in Remanufacturing</span></p>
                                </div>

                                <p dir="ltr" style="line-height: 1.2; margin-left: 0.686882pt; margin-top: 14.3613pt; margin-bottom: 0pt; text-align: center;"><a href="https://www.umwelt-campus.de/en/online-symposium-on-remanufacturing" rel="noopener noreferrer" target="_blank"><span style="font-size: 30px;"><span style="color: rgb(0, 0, 0);"><strong>Online Symposium on Innovation and&nbsp;</strong></span></span><span style="color: rgb(0, 0, 0);"><span style="font-size: 30px;"><strong>Automation in Remanufacturing</strong></span></span></a></p>

                                <p dir="ltr" style="line-height: 1.2; margin-left: 0.686882pt; margin-top: 14.3613pt; margin-bottom: 0pt; text-align: center;"><span style="font-size: 24px; font-family: Arial, Helvetica, sans-serif; color: rgb(0, 0, 0); background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;">April 26, 2023, 17:00 CET&nbsp;</span></p>

                                <p>Remanufacturing is one of the keys for developing circular economy technologies. Used parts of electronic or mechanical products can be made new again, sometimes even with a specification better than the original parts. In order to reach this aim very sophisticated processes containing inspection, disassembly, cleaning, reprocessing and assembly/testing are necessary.
                                    <br>
                                    <br>In the series of the successful symposia on innovation and automation in remanufacturing Prof. Matthias Vette-Steinkamp and Prof. Wolfgang Gerke from Trier University of Applied Sciences &ndash; Environmental Campus Birkenfeld together with their friend the remanufacturing specialist Mr. Fernand Weiland organize the 11th symposium on Innovation and Automation in Remanufacturing with the special topic: Reman and Automation/Reman and Steel industry.
                                    <br>
                                    <br>It is an interesting question why it takes so long to automate processes in remanufacturing. In the symposium the results of a project focused on the questions &lsquo;why and where automation could be implemented in remanufacturing processes&rsquo; will be presented by Prof. Erik Sundin. Remanufacturing is implemented since a long time in automotive industry. But as an important method of circular economy other industries should consider remanufacturing too. Dr. Rachel Waugh will give answers to the question why the steel industry is interested in remanufacturing.
                                    <br>
                                    <br>Professor Erik Sundin , Professor at Link&ouml;ping University, Sweden will give a presentation with the title, RR &ndash; &lsquo;Automation in Repair and Remanufacturing&rsquo;. Erik Sundin conducted his PhD at Link&ouml;ping University within the area of remanufacturing in year 2004. Most of Erik&rsquo;s research has been focusing on remanufacturing but recently topics of Sustainable Manufacturing and Product as a Service (PaaS) also have become a part of his research. During 2018 and 2021 he and his colleagues conducted a national research project called ARR in Sweden with 5 companies to understand why and where automation could be implemented in remanufacturing processes. This presentation will conclude the findings from the project and parts of the research papers that the project resulted in.
                                    <br>
                                    <br>Dr. Raquel Waugh, Principal consultant at Oakdene Hollins will give the second presentation with the title: &lsquo;The Remanufacturing Working Group &ndash; why the steel industry is interested in remanufacturing&rsquo;. Since 2019, Rachel has been working with the global steel industry association, worldsteel, to better understand the status and prospects of remanufacturing, one of the four pillars of the Circular Economy they have identified as of particular relevance to steel. This presentation describes the work to date, including the launch of the Remanufacturing Working Group, which aims to bring together remanufacturing practitioners from across different steel-intensive sectors, to monitor the trends of the industry through an interactive tracker, and explore key topics of interest, such as carbon footprinting of remanufactured products.
                                    <br>
                                    <br>Following the presentations, we are pleased that Mr. Peter Bartel has agreed to comment and animate the discussion as a panellist. Peter Bartel is currently in management position as Marketing / Engineering Director at C-ECO Circular Economy Solutions GmbH. Peter has an engineering degree from DHBW Mannheim (Germany). Peter is currently member of the Board of the Automotive Parts Remanufacturer Association (Apra Europe) and Vice Chairman of this association. Prior to that he was many years Chairman of that association. Peter is a top expert for remanufacturing all automotive components, in 2017 he was honoured by ReMaTec as the Remanufacturer of the year.
                                    <br>
                                    <br>The Video-Audience will also be invited to ask questions. The organisers are delighted to have again the support of ReMaTec, APRA, FIRM and the European Remanufacturing Council (ERC).
                                    <br>
                                    <br>The video conference will be held on 26.04.2023 at 17:00 CET, duration about 1.5 hours.</p>
                                <div style="margin:auto; text-align: center;background-color: #4CAF50; border: none; color: white; padding: 15px 32px; text-align: center; text-decoration: none;  font-size: 16px; border-radius:8px; width:50%;"><span style="color: rgb(255, 255, 255);"><a href="https://seu2.cleverreach.com/f/269935-287569/" rel="noopener noreferrer" target="_blank"><span style="font-size: 24px;">Register Now!</span></a></span></div>
                                <hr style="text-align: center;">
                                <div data-empty="true" style="text-align: center;">
                                    <br>
                                </div>
                                <div style="text-align: center;">
                                    <a href="http://coresupply.com/" rel="noopener noreferrer" style=" user-select: auto;" target="_blank"><img style="position: relative; max-width: 100%; cursor: pointer; padding: 0px 1px;" src="https://res.cloudinary.com/micronetonline/image/upload/c_limit,w_1320,h_360/v1621882787/tenants/78d69308-ea4b-4397-80cf-05c95cee98af/69f49e7dcf7a48ca9347e415cb3f42fc/RAS-Banner-2021.jpg" width="633" height="174"></a></div>
                                <hr>

                                <p>
                                    <br>
                                </p>
                                <div style="margin: 0px; outline: none; padding: 0px; color: inherit; font-size: 16px; font-weight: inherit; line-height: 1.4; text-decoration: inherit;">
                                    <br>
                                </div>
                                <hr>
                                <br>
                                <div style="text-align: center;">
                                    <a href="https://www.oec2021ads.com/autoparts" style="color: inherit; user-select: auto;"><img style="position: relative; max-width: calc(100% - 10px); cursor: pointer; padding: 0px 1px; display: inline-block; vertical-align: bottom; margin-right: 5px; margin-left: 5px; text-align: center;" src="https://automotivepartsremanufacturersassociations.growthzoneapp.com/ap/CloudFile/Download/P7lnyAVL" width="626" height="174"></a></div>
                                <br>

                                <table style="font-family: sans-serif; color: rgb(51, 51, 51); margin-left: calc(0%); width: 100%;" width="100%">
                                    <tbody>
                                        <tr style="user-select: none;">
                                            <td colspan="2" style="padding: 10px; font-size: 20px; font-family: sans-serif; text-align: center; color: rgb(255, 255, 255); background-color: rgb(52, 122, 163); width: 72.1677%;"><span style="font-size: 18px;"><strong style="font-weight: 700;">INDUSTRY CALENDAR - UPCOMING EVENTS</strong></span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><a href="https://apraeurope.org/apra-european-symposium/" rel="noopener noreferrer" target="_blank"><span style="font-size: 18px;">APRA European Symposium 2023</span></a></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">14-16 March 2023 &nbsp;<br>&nbsp;Pamplona, Navarra, Spain</span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><a href="https://www.electricalrebuilders.org/index.php/" rel="noopener noreferrer" target="_blank"><span style="font-size: 18px;">2023 ERA Trade Show</span></a></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">23-25 March 2023 &nbsp;<br>&nbsp;Seattle, WA, USA</span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><a href="https://www.autoamericas.show/es/" rel="noopener noreferrer" target="_blank"><span style="font-size: 18px;">AutoAmericas 2023</span></a></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">24-25 March 2023 &nbsp;<br>&nbsp;Miami, FL, USA</span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><a href="https://remancouncil.org/remanday/" rel="noopener noreferrer" target="_blank"><span style="font-size: 18px;">2023 REMAN DAY</span></a></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">13 April 2023 &nbsp;<br>&nbsp;Global</span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><a href="https://www.umwelt-campus.de/en/online-symposium-on-remanufacturing" rel="noopener noreferrer" target="_blank"><span style="font-size: 18px;">11th Online Symposium on Innovation and Automation in Remanufacturing&nbsp;</span></a></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">26 April 2023 &nbsp;<br>&nbsp;Online</span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><a href="https://www.rematec.com/amsterdam/about-the-exhibition/" rel="noopener noreferrer" target="_blank"><span style="font-size: 18px;">Rematec 2023</span></a></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">27-29 June 2023 &nbsp;<br>&nbsp;Amsterdam, Netherlands</span></td>
                                        </tr>
                                        <tr style="user-select: none;">
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 46.9033%;"><a href="https://ina-paace-automechanika-mexico-city.us.messefrankfurt.com/mexico-city/en.html" rel="noopener noreferrer" target="_blank"><span style="font-size: 18px;">INA/PAACE Automechanika</span></a></td>
                                            <td style="border-bottom: 1px solid black; padding: 5px; vertical-align: top; width: 25.2644%;"><span style="font-size: 18px;">12-14 July 2023 &nbsp;<br>&nbsp;Mexico City, Mexico</span></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p style="font-family: sans-serif; font-size: 14px; text-align: center;"><span style="font-size: 18px;">Have information you&#39;d like to share with the industry? Contact Us!<br>Want to advertise in this and other APRA publications? <a href="https://apra.org/#/Advertising" style="color: inherit; user-select: auto;" target="_blank">Click Here</a>&nbsp;</span>
                                    <br><span style="font-size: 18px;">Automotive Parts Remanufacturers Association<br>1520 Belle View Blvd - 3097<br>Alexandria, Virginia 22307-6531<br>P: (703) 968-2772<br>info@apra.org | <a href="https://apra.org" style="color: inherit; user-select: auto;">www.apra.org</a></span></p>
                            </td>
                        </tr>
                        <tr style="user-select: none;">
                            <td style="text-align: left; width: 72.6208%;"><span style="font-size: 18px;"><br></span></td>
                            <td style="min-width: 5px; border: 1px solid rgb(221, 221, 221); user-select: text; width: 0.9063%;"><span style="font-size: 18px;">&nbsp;</span></td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </tbody>
</table>