import { Component, OnInit } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import {SessionDataService} from '../session-data.service'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'


@Component({
  selector: 'app-board-of-directors',
  templateUrl: './board-of-directors.component.html',
  styleUrls: ['./board-of-directors.component.css']
})
export class BoardOfDirectorsComponent implements OnInit {
  constructor( private data: SessionDataService) { }

  animationTrigger:string;

  ngOnInit(): void {
   this.data.uncheckAll();
  }

}